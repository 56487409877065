import React from "react";

export type DeleteNodeButtonProps = {
  id: string;
  onDelete: (id: string) => void;
};

const DeleteNodeButton: React.FC<DeleteNodeButtonProps> = ({
  id,
  onDelete,
}) => {
  return (
    <button
      onClick={() => onDelete(id)}
      className="absolute top-0 right-0 m-2 flex items-center justify-center bg-red-500 hover:bg-red-600 text-white p-2 rounded-full"
      aria-label="Delete"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
};

export default DeleteNodeButton;
