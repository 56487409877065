import React from 'react';
import { motion } from 'framer-motion';
import { FiX, FiArrowLeft, FiMessageSquare } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const PaymentErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#1a0b26] flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full"
      >
        <div className="bg-[#2a1b3d] rounded-2xl p-8 text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", delay: 0.2 }}
            className="w-20 h-20 bg-gradient-to-r from-red-500 to-pink-500 rounded-full mx-auto mb-6 flex items-center justify-center"
          >
            <FiX className="text-white text-4xl" />
          </motion.div>

          <h1 className="text-3xl font-bold text-white mb-4">
            Payment Failed
          </h1>

          <p className="text-gray-400 mb-8">
            We couldn't process your payment. This could be due to insufficient funds, 
            incorrect card details, or a temporary issue with your payment provider.
          </p>

          <div className="space-y-3">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => navigate('/')}
              className="w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg font-medium hover:from-purple-700 hover:to-pink-700 transition-colors flex items-center justify-center gap-2"
            >
              <FiArrowLeft />
              Return to Dashboard
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => navigate('/support')}
              className="w-full py-3 bg-purple-600/20 text-purple-400 rounded-lg font-medium hover:bg-purple-600/30 transition-colors flex items-center justify-center gap-2"
            >
              <FiMessageSquare />
              Contact Support
            </motion.button>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="mt-6 text-sm text-gray-400"
          >
            Error Code: PAY_ERR_001
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default PaymentErrorPage; 