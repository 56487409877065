// src/components/NodeHandle.tsx
import React from "react";
import { Handle, Position } from "react-flow-renderer";

const handleStyle = {
  width: 16,
  height: 16,
  borderRadius: "50%",
  backgroundColor: "#555",
  border: "2px solid white",
};

const NodeHandle = ({
  id,
  type,
  position,
  style,
  label,
  handleColors,
  handleAnimations,
  onHover,
  onLeave,
}: {
  id: string;
  type: "source" | "target";
  position: Position;
  style?: React.CSSProperties;
  label: string;
  handleColors?: { [key: string]: string };
  handleAnimations?: { [key: string]: string };
  onHover: () => void;
  onLeave: () => void;
}) => {
  return (
    <>
      <Handle
        type={type}
        position={position}
        id={id}
        style={{
          ...handleStyle,
          ...style,
          backgroundColor: handleColors?.[id],
        }}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        className={handleAnimations?.[id]}
      />
      <span
        className="absolute text-white text-sm"
        style={{
          [type === "source" ? "right" : "left"]: [type === "source" ? "16px" : "-16px"],
          top: style?.top,
          transform: [type === "source" ? "translateY(-50%)" : "translate(-100%,-50%)"] as unknown as string,
        }}
      >
        {label}
      </span>
    </>
  );
};

export default NodeHandle;