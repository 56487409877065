// src/components/ProjectManager/FlowManager.tsx
import {
  Edge as ReactFlowEdge,
  Node as ReactFlowNode,
} from "react-flow-renderer";
import { Flow, Block, Connection, Node, Edge } from "../../../types/flowTypes";
import { Dispatch, SetStateAction } from "react";

const blockTypeMapping: { [key: string]: string } = {
  time: "Time Trigger",
  email_check: "Check Email",
  send_email: "Send Email",
  generate_video: "Generate Video",
  publish_yt_shorts: "Publish YT Shorts",
  publish_x: "Publish on X",
};

class FlowManager {
  private static selectedFlowId: string | null = null;

  static loadProject(
    flowId: string,
    flows: Flow[],
    blocks: Block[],
    connections: Connection[],
    setNodes: Dispatch<SetStateAction<ReactFlowNode[]>>,
    setEdges: Dispatch<SetStateAction<ReactFlowEdge[]>>
  ): void {
    const flow = flows.find((f) => f.id === flowId);
    if (!flow) return;

    const flowBlocks = blocks.filter((block) => block.flow === flowId);
    const flowConnections = connections.filter(
      (connection) =>
        flowBlocks.some((block) => block.id === connection.source_block) &&
        flowBlocks.some((block) => block.id === connection.target_block)
    );

    setNodes(
      flowBlocks.map((block) => ({
        id: block.data.id!,
        data: block.data,
        position: { x: block.position_x, y: block.position_y },
        type: blockTypeMapping[block.block_type],
      }))
    );

    setEdges(
      flowConnections.map((connection) => {
        const sourceBlock = flowBlocks.find(
          (block) => block.id === connection.source_block
        );
        const targetBlock = flowBlocks.find(
          (block) => block.id === connection.target_block
        );

        return {
          id: connection.id!,
          source: sourceBlock?.data?.id || "Unknown Source", // Fallback to a default value if sourceBlock is undefined
          target: targetBlock?.data?.id || "Unknown Target", // Fallback to a default value if targetBlock is undefined
          sourceHandle: `${connection.connection_type.split("-")[0]}-out`,
          targetHandle: `${connection.connection_type.split("-")[0]}-in`,
          connection_type: connection.connection_type,
          type: 'custom',
          data: {
            sourceNodeID: sourceBlock?.data?.id || "Unknown Source",
            sourceHandleID: `${connection.connection_type.split("-")[0]}-out`,
            targetNodeID: targetBlock?.data?.id || "Unknown Target",
            targetHandleID: `${connection.connection_type.split("-")[0]}-in`,
          },
        };
      })
    );

    // console.log("Loaded nodes and edges:");
    // console.log(
    //   flowBlocks.map((block) => ({
    //     id: block.id!,
    //     data: block.data,
    //     position: { x: block.position_x, y: block.position_y },
    //     type: blockTypeMapping[block.block_type],
    //   }))
    // );
    // console.log(
    //   flowConnections.map((connection) => ({
    //     id: connection.id!,
    //     source: connection.source_block,
    //     target: connection.target_block,
    //     sourceHandle: connection.connection_type.split("-")[0],
    //     targetHandle: connection.connection_type.split("-")[1],
    //     connection_type: connection.connection_type,
    //   }))
    // );
    this.selectedFlowId = flowId;
  }

  static getSelectedFlowId(): string | null {
    return this.selectedFlowId;
  }

  static setSelectedFlowId(flowId: string | null): void {
    this.selectedFlowId = flowId;
  }
}

export default FlowManager;
