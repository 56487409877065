// src/components/PublishXNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import NewXAccountModal from "../modals/NewXAccountModal";

const PublishXNode = ({
  id,
  data,
  onDelete,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [content, setContent] = useState(data.content || "");
  const [file, setFile] = useState<File | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<string>("");
  const [accounts, setAccounts] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [replyPermissions, setReplyPermissions] = useState("Everyone");

  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();

  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleAddAccount = (newAccount: string) => {
    if (newAccount.trim() && !accounts.includes(newAccount.trim())) {
      setAccounts([...accounts, newAccount.trim()]);
      setSelectedAccount(newAccount.trim());
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleSelectAccount = (account: string) => {
    setSelectedAccount(account);
    setDropdownVisible(false);
  };

  return (
    <NodeContainer
      id={id}
      className="bg-[#763FB2]"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold">Publish on X</h3>
      <label>Choose Account:</label>
      <div className="relative">
        <div
          className={`${darkBlueInputStyle} w-full cursor-pointer flex justify-between items-center`}
          onClick={handleDropdownToggle}
        >
          {selectedAccount || "Select an X Account"}
          <span className="ml-2">&#9660;</span>
        </div>
        {dropdownVisible && (
          <ul className="absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10">
            {accounts.map((account, index) => (
              <li
                key={index}
                className="p-2 hover:bg-gray-700 cursor-pointer"
                onClick={() => handleSelectAccount(account)}
              >
                {account}
              </li>
            ))}
            <li
              className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
              onClick={() => {
                setModalVisible(true);
                setDropdownVisible(false);
              }}
            >
              Add Account
            </li>
          </ul>
        )}
      </div>
      <label className="block mt-2">Content:</label>
      <textarea
        placeholder="Text content (up to 280 characters)"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className={darkBlueInputStyle}
        maxLength={280}
      />
      <label className="block mt-2">Media:</label>
      <input
        type="file"
        accept="image/*,video/*"
        multiple
        className={darkBlueInputStyle}
        onChange={(e) => {
          const files = e.target.files;
          if (files) {
            data.files = Array.from(files);
          }
        }}
      />
      <label className="block mt-2">Who Can Reply:</label>
      <select
        value={replyPermissions}
        onChange={(e) => setReplyPermissions(e.target.value)}
        className={darkBlueInputStyle}
        >
          <option value="Everyone">Everyone</option>
          <option value="People you follow">People you follow</option>
          <option value="Only people you mention">Only people you mention</option>
        </select>
  
        <NodeHandle
          id="signal-in"
          type="target"
          position={Position.Left}
          label="Send Signal"
          style={{ top: "20%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Start publishing signal from the previous block.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="text-in"
          type="target"
          position={Position.Left}
          label="Content"
          style={{ top: "40%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Optional input: text content.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="file-in"
          type="target"
          position={Position.Left}
          label="Media"
          style={{ top: "60%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Optional input: media files.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="signal-out"
          type="source"
          position={Position.Right}
          label="Published"
          style={{ top: "50%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Content published successfully.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="error-out"
          type="source"
          position={Position.Right}
          label="Error"
          style={{ top: "80%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Publishing content failed.")}
          onLeave={handleMouseLeave}
        />
        {modalVisible && (
          <NewXAccountModal
            onClose={handleModalClose}
            onAddAccount={handleAddAccount}
          />
        )}
      </NodeContainer>
    );
  };
  
  export default PublishXNode;