import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../api/authService';
import { setUser } from '../features/user/userSlice';
import { useDispatch } from 'react-redux';
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    setCredentials({
      ...credentials,
      [id]: value
    });

    switch (id) {
      case 'email':
        setErrors({
          ...errors,
          email: validateEmail(value) ? '' : 'Invalid email address'
        });
        break;
      case 'password':
        setErrors({
          ...errors,
          password: value ? '' : 'Password is required'
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!credentials.email || !credentials.password || errors.email || errors.password) {
      setErrors({
        email: !credentials.email ? 'Email is required' : errors.email,
        password: !credentials.password ? 'Password is required' : errors.password
      });
      return;
    }

    try {
      const response = await login(credentials.email, credentials.password);
      dispatch(setUser({ user: response.user, token: response.access, refresh: response.refresh }));
      console.log('Login successful:', response);
      navigate('/'); // Redirect to the dashboard after successful login
    } catch (error) {
      console.error('Login failed:', error);
      // Optionally handle login failure (e.g., showing an error message)
    }
  };

  return (
    <div className="relative min-h-screen bg-main-gradient flex items-center justify-center p-4">
      {/* Background elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/50" />

      {/* Login Form */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative w-full max-w-md backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 shadow-2xl p-8 z-10"
      >
        {/* Decorative elements */}
        <div className="absolute -top-20 -right-20 w-40 h-40 bg-purple-500/30 rounded-full blur-3xl" />
        <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-pink-500/30 rounded-full blur-3xl" />

        <h2 className="text-4xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient text-center">
          Welcome Back
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Email Input */}
          <div>
            <label className="block text-white/80 text-sm font-medium mb-2">
              Email
            </label>
            <motion.input
              whileFocus={{ scale: 1.02 }}
              className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-purple-500 transition-all duration-300"
              id="email"
              type="email"
              placeholder="Enter your email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
            {errors.email && (
              <p className="text-red-400 text-xs mt-2">{errors.email}</p>
            )}
          </div>

          {/* Password Input */}
          <div>
            <label className="block text-white/80 text-sm font-medium mb-2">
              Password
            </label>
            <div className="relative">
              <motion.input
                whileFocus={{ scale: 1.02 }}
                className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-purple-500 transition-all duration-300 pr-12"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={credentials.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-white/60 hover:text-white/90 transition-colors"
              >
                {showPassword ? <RiEyeOffLine size={20} /> : <RiEyeLine size={20} />}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-400 text-xs mt-2">{errors.password}</p>
            )}
          </div>

          {/* Remember Me & Forgot Password */}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <motion.input
                whileFocus={{ scale: 1.1 }}
                type="checkbox"
                id="remember"
                className="w-4 h-4 rounded-lg bg-white/10 border-white/20 text-purple-500 focus:ring-purple-500 focus:ring-offset-0"
              />
              <label htmlFor="remember" className="ml-2 text-sm text-white/80">
                Remember me
              </label>
            </div>
            <Link
              to="/forgot-password"
              className="text-sm text-purple-400 hover:text-purple-300 transition-colors"
            >
              Forgot Password?
            </Link>
          </div>

          {/* Submit Button */}
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            className="w-full px-4 py-3 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
          >
            Sign In
          </motion.button>

          {/* Sign Up Link */}
          <div className="text-center mt-6">
            <p className="text-white/60">
              Don't have an account?{" "}
              <Link
                to="/register"
                className="text-purple-400 hover:text-purple-300 font-medium transition-colors"
              >
                Sign up here
              </Link>
            </p>
          </div>
        </form>

 
      </motion.div>
    </div>
  );
};

export default LoginForm;
