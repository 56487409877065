import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFlows, createFlow, updateFlow, deleteFlow } from '../../api/flowsService';
import { Flow } from '../../types/flowTypes';

// Define the state type
interface FlowsState {
    items: Flow[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Define the initial state with the correct type
const initialState: FlowsState = {
    items: [],
    status: 'idle',
    error: null,
};

export const fetchFlows = createAsyncThunk('flows/fetchFlows', async () => {
    return await getFlows();
});

export const addFlow = createAsyncThunk('flows/addFlow', async (newFlow: Flow) => {
    return await createFlow(newFlow);
});

export const modifyFlow = createAsyncThunk('flows/modifyFlow', async ({ id, updatedFlow }: {id: string, updatedFlow: Flow}) => {
    return await updateFlow(id, updatedFlow);
});

export const removeFlow = createAsyncThunk('flows/removeFlow', async (id: string) => {
    await deleteFlow(id);
    return id;
});

const flowsSlice = createSlice({
    name: 'flows',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlows.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFlows.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchFlows.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message as string;
            })
            .addCase(addFlow.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(modifyFlow.fulfilled, (state, action) => {
                const updatedFlow = action.payload;
                const index = state.items.findIndex((flow) => flow.id === updatedFlow.id);
                if (index !== -1) {
                    state.items[index] = updatedFlow;
                }
            })
            .addCase(removeFlow.fulfilled, (state, action) => {
                state.items = state.items.filter((flow) => flow.id !== action.payload);
            });
    }
});

export default flowsSlice.reducer;