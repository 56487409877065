import { Block, Connection } from '../../../types/flowTypes';

interface ConnectionValidationResult {
  isValid: boolean;
  errors: string[];
}

const findBlockById = (blocks: Block[], id: string): Block | undefined => {
  return blocks.find(block => block.id === id);
};

const findConnectedBlocks = (
  blockId: string, 
  connections: Connection[], 
  direction: 'incoming' | 'outgoing'
): string[] => {
  if (direction === 'incoming') {
    return connections
      .filter(conn => conn.target_label === blockId)
      .map(conn => conn.source_label);
  }
  return connections
    .filter(conn => conn.source_label === blockId)
    .map(conn => conn.target_label);
};

const validateChainStructure = (
  blocks: Block[], 
  connections: Connection[]
): ConnectionValidationResult => {
  const result: ConnectionValidationResult = {
    isValid: true,
    errors: []
  };

  // Find time trigger block
  const timeBlock = blocks.find(block => block.block_type === 'time');
  if (!timeBlock) {
    result.errors.push('Time trigger block not found');
    result.isValid = false;
    return result;
  }

  // Check if time block has any incoming connections
  const timeBlockIncoming = findConnectedBlocks(timeBlock.id!, connections, 'incoming');
  if (timeBlockIncoming.length > 0) {
    result.errors.push('Time trigger block cannot have incoming connections');
    result.isValid = false;
  }

  // Validate chain structure using BFS
  const visited = new Set<string>();
  const queue = [timeBlock.id!];
  visited.add(timeBlock.id!);

  while (queue.length > 0) {
    const currentBlockId = queue.shift()!;
    const outgoingConnections = findConnectedBlocks(currentBlockId, connections, 'outgoing');

    outgoingConnections.forEach(targetId => {
      if (!visited.has(targetId)) {
        visited.add(targetId);
        queue.push(targetId);
      }
    });
  }

  // Check if all blocks are connected
  blocks.forEach(block => {
    if (!visited.has(block.id!)) {
      result.errors.push(`Block "${block.data?.label || block.id}" is not connected to the flow chain (starting with Time Trigger)`);
      result.isValid = false;
    }
  });

  return result;
};

export { validateChainStructure }; 