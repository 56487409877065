// src/components/utils/flowUtils.ts

import { Node, Edge } from "react-flow-renderer";
import * as htmlToImage from 'html-to-image';
import { getRectOfNodes, getTransformForBounds } from '@reactflow/core';
import { toPng } from 'html-to-image';
import { ReactFlowInstance } from 'reactflow';

/**
 * Trigger success animation for a node handle.
 */
export const triggerSuccessAnimation = (
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>,
  nodeId: string,
  handleId: string
) => {
  setNodes((nds) =>
    nds.map((node) =>
      node.id === nodeId
        ? {
            ...node,
            data: {
              ...node.data,
              handleColors: {
                ...node.data.handleColors,
                [handleId]: "green",
              },
              handleAnimations: {
                ...node.data.handleAnimations,
                [handleId]: "pulse-success",
              },
            },
          }
        : node
    )
  );

  setTimeout(() => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                handleAnimations: {
                  ...node.data.handleAnimations,
                  [handleId]: "",
                },
                errorMessage: "",
              },
            }
          : node
      )
    );
  }, 600);
};

/**
 * Trigger rejection animation for a node handle.
 */
export const triggerRejectionAnimation = (
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>,
  nodeId: string,
  handleId: string,
  message?: string
) => {
  setNodes((nds) =>
    nds.map((node) =>
      node.id === nodeId
        ? {
            ...node,
            data: {
              ...node.data,
              handleColors: {
                ...node.data.handleColors,
                [handleId]: "red",
              },
              handleAnimations: {
                ...node.data.handleAnimations,
                [handleId]: "pulse-reject",
              },
              errorMessage: message || "",
            },
          }
        : node
    )
  );

  setTimeout(() => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                handleColors: {
                  ...node.data.handleColors,
                  [handleId]: "#555",
                },
                handleAnimations: {
                  ...node.data.handleAnimations,
                  [handleId]: "",
                },
                errorMessage: "",
              },
            }
          : node
      )
    );
  }, 600);
};

/**
 * Reset handle color.
 */
export const resetHandleColor = (
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>,
  nodeId: string,
  handleId: string,
  message?: string
) => {
  setNodes((nds) =>
    nds.map((node) =>
      node.id === nodeId
        ? {
            ...node,
            data: {
              ...node.data,
              handleColors: {
                ...node.data.handleColors,
                [handleId]: "#555",
              },
              errorMessage: message || "",
            },
          }
        : node
    )
  );
};

/**
 * Capture a thumbnail image from the React Flow instance.
 */

export const captureThumbnail = async (reactFlowInstance: any): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    try {
      const { nodes, edges } = reactFlowInstance.toObject();

      // Check if there are any nodes or edges
      if (nodes.length === 0 && edges.length === 0) {
        reject('Graph is empty.');
        return;
      }

      // Set canvas dimensions
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const graphWidth = 1000;
      const graphHeight = 1000;
      canvas.width = graphWidth;
      canvas.height = graphHeight;

      if (!context) {
        reject('Could not get 2D context.');
        return;
      }

      // Draw a background
      context.fillStyle = '#2f3136';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw nodes
      context.fillStyle = '#ffffff';
      nodes.forEach((node: any) => {
        context.fillStyle = '#007acc';
        context.fillRect(node.position.x, node.position.y, 50, 30);
        context.fillStyle = '#ffffff';
        context.fillText(node.data.label, node.position.x + 5, node.position.y + 20);
      });

      // Draw edges
      context.strokeStyle = '#888888';
      edges.forEach((edge: any) => {
        const sourceNode = nodes.find((node: any) => node.id === edge.source);
        const targetNode = nodes.find((node: any) => node.id === edge.target);
        if (sourceNode && targetNode) {
          context.beginPath();
          context.moveTo(sourceNode.position.x + 25, sourceNode.position.y + 15);
          context.lineTo(targetNode.position.x + 25, targetNode.position.y + 15);
          context.stroke();
        }
      });

      // Convert canvas to data URL
      const imageDataUrl = canvas.toDataURL('image/png');
      console.log(imageDataUrl);
      resolve(imageDataUrl);

    } catch (error) {
      console.error('Error capturing graph thumbnail:', error);
      reject(error);
    }
  });
};