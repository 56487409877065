// src/components/ProjectManager/ProjectManager.tsx
import React, { Dispatch, SetStateAction, useState } from "react";
import { useAppSelector } from "../../../app/store";
import SimplifiedView from "./SimplifiedView";
import AdvancedView from "./AdvancedView";
import {
    Edge as ReactFlowEdge,
    Node as ReactFlowNode,
  } from "react-flow-renderer";

interface ProjectManagerProps {
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  setNodes: React.Dispatch<React.SetStateAction<ReactFlowNode[]>>;
  setEdges: React.Dispatch<React.SetStateAction<ReactFlowEdge[]>>;
  captureThumbnail: (reactFlowInstance: HTMLDivElement) => Promise<string>;
  reactFlowInstance: React.RefObject<any>;
  simpleView: boolean;
  setTabActionEditor: ()=>void;
}

const ProjectManager: React.FC<ProjectManagerProps> = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  captureThumbnail,
  reactFlowInstance,
  simpleView,
  setTabActionEditor,
}) => {
  const flows = useAppSelector((state) => state.flows.items);
  const blocks = useAppSelector((state) => state.blocks.items);
  const connections = useAppSelector((state) => state.connections.items);
  const [selectedFlow, setSelectedFlow] = useState<string | null>(null);

  return simpleView ? (
    <SimplifiedView
      nodes={nodes}
      edges={edges}
      setNodes={setNodes}
      setEdges={setEdges}
      captureThumbnail={captureThumbnail}
      reactFlowInstance={reactFlowInstance}
      flows={flows}
      blocks={blocks}
      connections={connections}
      selectedFlow={selectedFlow}
      setSelectedFlow={setSelectedFlow}
      setTabActionEditor={setTabActionEditor}
    />
  ) : (
    <AdvancedView
      nodes={nodes}
      edges={edges}
      setNodes={setNodes}
      setEdges={setEdges}
      captureThumbnail={captureThumbnail}
      reactFlowInstance={reactFlowInstance}
      flows={flows}
      blocks={blocks}
      connections={connections}
      setTabActionEditor={setTabActionEditor}
      setSelectedFlow={setSelectedFlow}
    />
  );
};

export default ProjectManager;