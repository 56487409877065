import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import { register } from "../api/authService";
import { setUser } from "../features/user/userSlice";
import { useDispatch } from "react-redux";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password1: "",
    password2: "",
    passwordClue: "",
    avatar: null,
  });
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password1: "",
    password2: "",
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password: string) => {
    return password.length >= 8;
  };

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[@$!%*?&#]/.test(password)) strength += 1;
    setPasswordStrength(strength);
  };

  const handleChange = (event: any) => {
    const { name, value, type, files } = event.target;
    setUserData({
      ...userData,
      [name]: type === "file" ? files[0] : value,
    });

    switch (name) {
      case "username":
        setErrors({
          ...errors,
          username: value ? "" : "Username is required",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: validateEmail(value) ? "" : "Invalid email address",
        });
        break;
      case "password1":
        calculatePasswordStrength(value);
        setErrors({
          ...errors,
          password1: validatePassword(value)
            ? ""
            : "Password must be at least 8 characters",
        });
        break;
      case "password2":
        setErrors({
          ...errors,
          password2:
            value === userData.password1 ? "" : "Passwords do not match",
        });
        break;
      default:
        break;
    }
  };

  const handleTermsChange = () => {
    setAcceptTerms(!acceptTerms);
    setTermsError("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // Check for validation errors
    if (
      !userData.username ||
      !userData.email ||
      !userData.password1 ||
      !userData.password2
    ) {
      setErrors({
        username: !userData.username ? "Username is required" : "",
        email: !userData.email ? "Email is required" : "",
        password1: !userData.password1 ? "Password is required" : "",
        password2: !userData.password2 ? "Confirm Password is required" : "",
      });
      return;
    }

    if (
      errors.username ||
      errors.email ||
      errors.password1 ||
      errors.password2
    ) {
      return;
    }

    if (!acceptTerms) {
      setTermsError("You must accept the terms and conditions");
      return;
    }

    try {
      const response = await register(
        userData.username,
        userData.email,
        userData.password1,
        userData.password2,
        "*",
        userData.avatar as unknown as string
      );
      dispatch(
        setUser({
          user: response.user,
          token: response.access,
          refresh: response.refresh,
        })
      );
      console.log("Registration successful", response);
      setIsRegistrationSuccessful(true); // Show video after successful registration
      navigate("/");
    } catch (error) {
      console.error("Registration failed:", error);
      // Optionally handle registration failure (e.g., showing an error message)
    }
  };

  // const handleVideoEnded = () => {
  //   navigate("/"); // Redirect to the home page after the video ends
  // };

  return (
    <div className="relative min-h-screen bg-main-gradient flex items-center justify-center p-4">
      {/* Background elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/50" />
      


      {/* Registration Form */}
      {!isRegistrationSuccessful && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative w-full max-w-xl backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 shadow-2xl p-8 z-10 mt-16"
        >
          <h2 className="text-4xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient text-center">
            Create Your Account
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Username Input */}
            <div>
              <label className="block text-white/80 text-sm font-medium mb-2">
                Username
              </label>
              <motion.input
                whileFocus={{ scale: 1.02 }}
                className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-purple-500 transition-all duration-300"
                name="username"
                type="text"
                placeholder="Enter your username"
                value={userData.username}
                onChange={handleChange}
                required
              />
              {errors.username && (
                <p className="text-red-400 text-xs mt-2">{errors.username}</p>
              )}
            </div>

            {/* Email Input */}
            <div>
              <label className="block text-white/80 text-sm font-medium mb-2">
                Email
              </label>
              <motion.input
                whileFocus={{ scale: 1.02 }}
                className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-purple-500 transition-all duration-300"
                name="email"
                type="email"
                placeholder="Enter your email"
                value={userData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <p className="text-red-400 text-xs mt-2">{errors.email}</p>
              )}
            </div>

            {/* Password Input */}
            <div>
              <label className="block text-white/80 text-sm font-medium mb-2">
                Password
              </label>
              <div className="relative">
                <motion.input
                  whileFocus={{ scale: 1.02 }}
                  className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-purple-500 transition-all duration-300 pr-12"
                  name="password1"
                  type={showPassword1 ? "text" : "password"}
                  placeholder="Create a password"
                  value={userData.password1}
                  onChange={handleChange}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword1(!showPassword1)}
                  className="absolute right-4 top-1/2 -translate-y-1/2 text-white/60 hover:text-white/90 transition-colors"
                >
                  {showPassword1 ? <RiEyeOffLine size={20} /> : <RiEyeLine size={20} />}
                </button>
              </div>
              {errors.password1 && (
                <p className="text-red-400 text-xs mt-2">{errors.password1}</p>
              )}

              {/* Password Strength Indicator */}
              <div className="mt-3">
                <div className="h-1.5 bg-white/10 rounded-full overflow-hidden">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${(passwordStrength / 4) * 100}%` }}
                    className={`h-full rounded-full transition-all duration-300 ${
                      passwordStrength === 1 ? 'bg-red-500' : 
                      passwordStrength === 2 ? 'bg-yellow-500' : 
                      passwordStrength === 3 ? 'bg-blue-500' : 
                      passwordStrength === 4 ? 'bg-green-500' : ''
                    }`}
                  />
                </div>
                <p className="text-white/60 text-xs mt-1">
                  Password Strength: {
                    passwordStrength === 1 ? 'Weak' : 
                    passwordStrength === 2 ? 'Moderate' : 
                    passwordStrength === 3 ? 'Strong' : 
                    passwordStrength === 4 ? 'Very Strong' : 'Too Short'
                  }
                </p>
              </div>
            </div>

            {/* Confirm Password Input */}
            <div>
              <label className="block text-white/80 text-sm font-medium mb-2">
                Confirm Password
              </label>
              <div className="relative">
                <motion.input
                  whileFocus={{ scale: 1.02 }}
                  className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-purple-500 transition-all duration-300 pr-12"
                  name="password2"
                  type={showPassword2 ? "text" : "password"}
                  placeholder="Confirm your password"
                  value={userData.password2}
                  onChange={handleChange}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword2(!showPassword2)}
                  className="absolute right-4 top-1/2 -translate-y-1/2 text-white/60 hover:text-white/90 transition-colors"
                >
                  {showPassword2 ? <RiEyeOffLine size={20} /> : <RiEyeLine size={20} />}
                </button>
              </div>
              {errors.password2 && (
                <p className="text-red-400 text-xs mt-2">{errors.password2}</p>
              )}
            </div>

            {/* Terms and Conditions */}
            <div className="flex items-center">
              <motion.input
                whileFocus={{ scale: 1.1 }}
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={handleTermsChange}
                className="w-5 h-5 rounded-lg bg-white/10 border-white/20 text-purple-500 focus:ring-purple-500 focus:ring-offset-0"
              />
              <label className="ml-3 text-sm text-white/80">
                I accept the{" "}
                <a href="/terms" className="text-purple-400 hover:text-purple-300 underline">
                  Terms and Conditions
                </a>
              </label>
            </div>
            {termsError && (
              <p className="text-red-400 text-xs">{termsError}</p>
            )}

            {/* Submit Button and Login Link */}
            <div className="flex flex-col sm:flex-row items-center justify-between gap-4 pt-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                className="w-full sm:w-auto px-8 py-3 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
              >
                Create Account
              </motion.button>
              <Link
                to="/login"
                className="text-white/80 hover:text-white text-sm transition-colors"
              >
                Already have an account? Sign In
              </Link>
            </div>
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default RegisterForm;
