// src/modals/NewXAccountModal.tsx
import React, { useState } from "react";

type NewXAccountModalProps = {
  onClose: () => void;
  onAddAccount: (newAccount: string) => void;
};

const modalBackgroundStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalStyle: React.CSSProperties = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  width: "400px",
};

const inputStyle = "mt-2 p-2 bg-gray-800 text-white rounded-md w-full";

const NewXAccountModal = ({ onClose, onAddAccount }: NewXAccountModalProps) => {
  const [newAccount, setNewAccount] = useState("");
  const [testAccountVerified, setTestAccountVerified] = useState(false);

  const handleAddAccount = () => {
    if (newAccount.trim()) {
      onAddAccount(newAccount.trim());
      setNewAccount("");
      onClose();
    }
  };

  const handleVerifyAccount = () => {
    // Simulate verifying an X account
    setTestAccountVerified(true);
    setTimeout(() => setTestAccountVerified(false), 3000);
  };

  return (
    <div style={modalBackgroundStyle} onClick={onClose}>
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <h3 className="text-lg font-semibold mb-4">Manage X Accounts</h3>
        <label className="block mb-2">Add New X Account:</label>
        <input
          type="email"
          value={newAccount}
          onChange={(e) => setNewAccount(e.target.value)}
          placeholder="Enter account email"
          className={inputStyle}
        />
        <div className="flex justify-between mt-4">
          <button
            className="p-2 bg-green-500 text-white rounded-md"
            onClick={handleAddAccount}
          >
            Add Account
          </button>
          <button
            className="p-2 bg-yellow-500 text-white rounded-md"
            onClick={handleVerifyAccount}
          >
            Verify Account
          </button>
        </div>
        {testAccountVerified && (
          <div className="mt-4 text-sm text-green-500">Account verified!</div>
        )}
        <button
          className="mt-6 p-2 bg-red-500 text-white rounded-md w-full"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default NewXAccountModal;