import React, { useState } from "react";
import { getBezierPath, EdgeProps } from "react-flow-renderer";

interface CustomEdgeData {
  sourceNodeID: string;
  sourceHandleID: string;
  targetNodeID: string;
  targetHandleID: string;
}

export type CustomEdgeProps = EdgeProps & {
  style?: React.CSSProperties;
  onEdgeClick: (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    edgeId: string,
    sourceNodeID: string,
    sourceHandleID: string,
    targetNodeID: string,
    targetHandleID: string
  ) => void;
  data: CustomEdgeData;
};

const CustomEdge: React.FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  onEdgeClick,
}) => {
  const [hovered, setHovered] = useState(false);

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={{ ...style, strokeWidth: hovered ? 6 : 5 }}
        className={`react-flow__edge-path cursor-pointer`}
        d={edgePath}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={(event) =>
          onEdgeClick(
            event,
            id,
            data.sourceNodeID,
            data.sourceHandleID,
            data.targetNodeID,
            data.targetHandleID
          )
        }
      />
      {hovered && (
        <g>
          <rect
            x={(sourceX + targetX) / 2 - 40}
            y={(sourceY + targetY) / 2 - 10}
            width="80"
            height="18"
            rx="5"
            ry="5"
            className="fill-current text-gray-800"
            style={{ fill: "#16213e" }}
          />
          <text
            className="fill-current text-white text-[10px]"
            textAnchor="middle"
            alignmentBaseline="middle"
            style={{
              transform: `translate(${(sourceX + targetX) / 2}px, ${
                (sourceY + targetY) / 2
              }px)`,
              userSelect: "none",
              pointerEvents: "none",
            }}
          >
            Click to remove
          </text>
        </g>
      )}
    </>
  );
};

export default CustomEdge;
