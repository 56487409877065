import { Block, Connection, Flow } from '../../../types/flowTypes';
import { SubscriptionData, UsageData } from '../../../types/subscriptionTypes';
import { 
  validateTimeTriggerBlock, 
  validateEmailCheckBlock, 
  validateSendEmailBlock, 
  validateYouTubeShortsBlock,
  validateVideoGeneratorBlock 
} from './blockValidators';
import { validateChainStructure } from './connectionValidators';
interface ValidationError {
  blockId: string;
  field: string;
  message: string;
  type?: 'subscription' | 'validation';
}

interface FlowValidationResult {
  isValid: boolean;
  errors: {
    flow?: string[];
    blocks: ValidationError[];
  };
  subscriptionErrors?: ValidationError[];
}

const validateTimeBlock = (blocks: Block[]): boolean => {
  const timeBlocks = blocks.filter(block => block.block_type === 'time');
  return timeBlocks.length === 1;
};

const validateMinimumBlocks = (blocks: Block[]): boolean => {
  const nonTimeBlocks = blocks.filter(block => block.block_type !== 'time');
  return nonTimeBlocks.length >= 1;
};

export const validateFlow = (
  flow: Flow, 
  blocks: Block[], 
  connections: Connection[],
  usage: UsageData | null
): FlowValidationResult => {
  const result: FlowValidationResult = {
    isValid: true,
    errors: {
      flow: [],
      blocks: []
    },
    subscriptionErrors: []
  };

  // Validate flow name and description
  if (!flow.name?.trim()) {
    result.errors.flow?.push('Flow name is required');
  }

  if (!flow.description?.trim()) {
    result.errors.flow?.push('Flow description is required');
  }

  // Validate time block existence
  if (!validateTimeBlock(blocks)) {
    result.errors.flow?.push('Flow must have exactly one Time Trigger block');
  }

  // Validate minimum blocks requirement
  if (!validateMinimumBlocks(blocks)) {
    result.errors.flow?.push('Flow must have at least one action block besides Time Trigger');
  }

  // Validate individual blocks
  blocks.forEach(block => {
    let blockErrors: ValidationError[] = [];
    switch (block.block_type) {
      case 'time':
        blockErrors = validateTimeTriggerBlock(block, usage);
        break;
      case 'email_check':
        blockErrors = validateEmailCheckBlock(block);
        break;
      case 'send_email':
        blockErrors = validateSendEmailBlock(block);
        break;
      case 'publish_yt_shorts':
        blockErrors = validateYouTubeShortsBlock(block);
        break;
      case 'generate_video':
        blockErrors = validateVideoGeneratorBlock(block);
        break;
    }

    // Separate subscription errors from regular validation errors
    blockErrors.forEach(error => {
      if (error.type === 'subscription') {
        result.subscriptionErrors?.push(error);
      } else {
        result.errors.blocks.push(error);
      }
    });
  });

    // Validate connections
    const connectionValidation = validateChainStructure(blocks, connections);
    if (!connectionValidation.isValid) {
      connectionValidation.errors.forEach((error: string) => {
        result.errors.flow?.push(error);
      });
    }

  result.isValid = !result.errors.flow?.length && 
                   !result.errors.blocks.length && 
                   !result.subscriptionErrors?.length;
  return result;
}; 