import React, { createContext, useContext, useState, useCallback } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import { ToastMessage, ToastType, ToastStyles } from './types';
import ToastContainer from './ToastContainer';

const toastStyles: ToastStyles = {
  success: {
    style: 'bg-dark-sea-green/90 text-white border border-dark-sea-green/20',
    icon: <FaCheckCircle />
  },
  error: {
    style: 'bg-indian-red/90 text-white border border-indian-red/20',
    icon: <FaExclamationCircle />
  },
  info: {
    style: 'bg-dodger-blue/90 text-white border border-dodger-blue/20',
    icon: <FaInfoCircle />
  },
  warning: {
    style: 'bg-orange-400/90 text-white border border-orange-400/20',
    icon: <FaExclamationTriangle />
  }
};

interface ToastContextType {
  showToast: (
    content: string, 
    type?: ToastType, 
    title?: string, 
    duration?: number,
    onClick?: () => void
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const removeToast = useCallback((id: string) => {
    setMessages(messages => messages.filter(message => message.id !== id));
  }, []);

  const showToast = useCallback((
    content: string, 
    type: ToastType = 'info', 
    title?: string, 
    duration: number = 5000,
    onClick?: () => void
  ) => {
    const id = Math.random().toString(36).substr(2, 9);
    const toast: ToastMessage = {
      id,
      content,
      title,
      type,
      duration,
      icon: toastStyles[type].icon,
      style: toastStyles[type].style,
      onClick
    };

    setMessages(messages => [...messages, toast]);
    setTimeout(() => removeToast(id), duration);
  }, [removeToast]);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer messages={messages} removeToast={removeToast} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}; 