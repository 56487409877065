import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectionsByFlow, createConnection, updateConnection, deleteConnection } from '../../api/flowsService';
import { Connection } from '../../types/flowTypes';

// Define the state type
interface ConnectionsState {
    items: Connection[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Define the initial state with the correct type
const initialState: ConnectionsState = {
    items: [],
    status: 'idle',
    error: null,
};

export const fetchConnectionsByFlow = createAsyncThunk('connections/fetchConnectionsByFlow', async (flowId: string) => {
    return await getConnectionsByFlow(flowId);
});

export const addConnection = createAsyncThunk('connections/addConnection', async (newConnection: Connection) => {
    return await createConnection(newConnection);
});

export const modifyConnection = createAsyncThunk('connections/modifyConnection', async ({ id, updatedConnection }: {id: string, updatedConnection: Connection}) => {
    return await updateConnection(id, updatedConnection);
});

export const removeConnection = createAsyncThunk('connections/removeConnection', async (id: string) => {
    await deleteConnection(id);
    return id;
});

const connectionsSlice = createSlice({
    name: 'connections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConnectionsByFlow.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConnectionsByFlow.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchConnectionsByFlow.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message as string;
            })
            .addCase(addConnection.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(modifyConnection.fulfilled, (state, action) => {
                const updatedConnection = action.payload;
                const index = state.items.findIndex((connection) => connection.id === updatedConnection.id);
                if (index !== -1) {
                    state.items[index] = updatedConnection;
                }
            })
            .addCase(removeConnection.fulfilled, (state, action) => {
                state.items = state.items.filter((connection) => connection.id !== action.payload);
            });
    }
});

export default connectionsSlice.reducer;