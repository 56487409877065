import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiCheck, FiCreditCard, FiSettings, FiArrowUp, FiX } from 'react-icons/fi';
import { RiVipCrownFill } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from '../app/store';
import { 
  fetchUsageStats, 
  fetchSubscriptionDetails,
  cancelSubscription,
  selectUsage,
  selectSubscription,
  selectIsLoading,
  selectError 
} from '../features/user/subscriptionSlice';
import subscriptionService from '../api/subscriptionService';
import { PaymentMethod } from '../types/subscriptionTypes';
import UsageStats from '../components/subscription/UsageStats';

interface Plan {
  id: string;
  name: string;
  price: string;
  description: string;
  features: string[];
  popular: boolean;
}

const SubscriptionPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const usage = useAppSelector(selectUsage);
  const subscription = useAppSelector(selectSubscription);
  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'yearly'>('monthly');

  // Animacje
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const plans: Plan[] = [
    {
      id: 'free',
      name: 'Free',
      price: 'FREE',
      description: 'Perfect for trying out',
      features: [
        'Max action flows: 2',
        'Basic notifications',
        '30min trigger delay',
        '100 monthly notifications',
        '7-day data retention'
      ],
      popular: false
    },
    {
      id: 'basic',
      name: 'Basic',
      price: '$19.99',
      description: 'For individual professionals',
      features: [
        'Max action flows: 5',
        'Advanced notifications',
        '15min trigger delay',
        '500 monthly notifications',
        '30-day data retention'
      ],
      popular: false
    },
    {
      id: 'premium',
      name: 'Premium',
      price: '$49.99',
      description: 'For growing businesses',
      features: [
        'Max action flows: 20',
        'Premium notifications',
        '5min trigger delay',
        '2000 monthly notifications',
        '90-day data retention'
      ],
      popular: true
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      price: 'Custom',
      description: 'For large organizations',
      features: [
        'Unlimited action flows',
        'Custom solutions',
        'Priority support',
        'Custom development',
        'On-premise options'
      ],
      popular: false
    }
  ];

  // Load subscription data
  useEffect(() => {
    const loadSubscriptionData = async () => {
      try {
        dispatch(fetchUsageStats());
        dispatch(fetchSubscriptionDetails());
        const paymentData = await subscriptionService.getPaymentMethods();
        setPaymentMethods(paymentData);
      } catch (err) {
        console.error(err);
      }
    };

    loadSubscriptionData();
  }, [dispatch]);

  // Handle subscription changes
  const handleSubscriptionChange = async (planId: string) => {
    if (planId === 'enterprise') {
      window.location.href = '/contact-sales';
      return;
    }

    if (planId === 'free') {
      try {
        await dispatch(cancelSubscription()).unwrap();
        dispatch(fetchUsageStats());
        return;
      } catch (err) {
        console.error(err);
        return;
      }
    }

    try {
      const isNewSubscription = !subscription;
      const { sessionId } = await subscriptionService.createCheckoutSession(
        planId, 
        isNewSubscription,
        billingPeriod
      );
      
      window.location.href = sessionId;
    } catch (err) {
      console.error(err);
    }
  };

  // Handle subscription cancellation
  const handleCancelSubscription = async () => {
    try {
      await dispatch(cancelSubscription()).unwrap();
      dispatch(fetchUsageStats());
    } catch (err) {
      console.error(err);
    }
  };

  const renderSubscriptionManager = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto py-12 px-4"
    >
      {error && (
        <div className="bg-red-500/20 border border-red-500/50 text-red-500 px-4 py-2 rounded-lg text-center mb-8">
          {error}
        </div>
      )}

      {/* Current Plan Status */}
      <div className="bg-[#3a2b4d] p-6 rounded-xl mb-8">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2 className="text-2xl font-bold text-white">
              {(usage?.subscription?.tier ?? 'Free').charAt(0).toUpperCase() + (usage?.subscription?.tier ?? 'Free').slice(1)} Plan
            </h2>
            {usage?.subscription?.tier !== 'free' && (
              <p className="text-gray-400">
                Next billing date: {new Date(usage?.subscription?.expires || '').toLocaleDateString()}
              </p>
            )}
          </div>
          {usage?.subscription?.status === 'canceled' && (
            <span className="px-3 py-1 bg-red-600/20 text-red-400 rounded-full text-sm">
              Cancels at period end
            </span>
          )}
        </div>
      </div>

      {/* Usage Stats */}
      <UsageStats/>

      {/* Cancel Button */}
      {usage?.subscription?.status === 'active' && 
       usage?.subscription?.tier !== 'free' && 
       !usage?.subscription?.status.includes('cancel') && (
        <div className="text-center">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="text-red-400 hover:text-red-300 transition-colors"
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </motion.button>
        </div>
      )}
    </motion.div>
  );

  // Keep existing renderPricingPlans function
  const renderPricingPlans = () => (
    <section className="py-20 px-4">
    <div className="max-w-7xl mx-auto">
      <motion.div 
        className="text-center mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">
          Choose Your Perfect Plan
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Select the plan that best fits your needs. All plans include our core features with different usage limits.
        </p>
        
        {/* Billing period toggle */}
        <div className="mt-8 inline-flex items-center gap-4 bg-[#2a1b3d] p-2 rounded-full">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`px-6 py-2 rounded-full ${
              billingPeriod === 'monthly' 
                ? 'bg-purple-600 text-white' 
                : 'text-gray-400 hover:text-white'
            } font-medium`}
            onClick={() => setBillingPeriod('monthly')}
          >
            Monthly
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`px-6 py-2 rounded-full ${
              billingPeriod === 'yearly' 
                ? 'bg-purple-600 text-white' 
                : 'text-gray-400 hover:text-white'
            } font-medium`}
            onClick={() => setBillingPeriod('yearly')}
          >
            Yearly
            <span className="ml-2 text-xs text-purple-400">Save 20%</span>
          </motion.button>
        </div>
      </motion.div>

      <motion.div 
        className="grid md:grid-cols-4 gap-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {plans.map((plan, index) => (
          <motion.div
            key={plan.id}
            variants={itemVariants}
            transition={{ delay: index * 0.1 }}
            className={`relative bg-[#2a1b3d] p-6 rounded-xl border ${
              plan.popular ? 'border-purple-600' : 'border-purple-600/20'
            } hover:border-purple-600/60 transition-all duration-300 ${
              plan.id === usage?.subscription?.tier ? 'ring-2 ring-purple-500' : ''
            }`}
            whileHover={{ y: -5, boxShadow: '0 10px 30px -15px rgba(139, 92, 246, 0.3)' }}
          >
            {plan.id === usage?.subscription?.tier && (
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <span className="bg-purple-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                  Current Plan
                </span>
              </div>
            )}

            <div className="text-center mb-6">
              <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">
                {plan.name}
              </h3>
              <div className="flex flex-col items-center gap-1 mb-4">
                {plan.price === 'FREE' ? (
                  <span className="text-4xl font-bold text-white">FREE</span>
                ) : plan.price !== 'Custom' && billingPeriod === 'yearly' ? (
                  <>
                    <span className="text-4xl font-bold text-white">
                      ${(parseFloat(plan.price.replace('$', '')) * 0.8).toFixed(2)}
                    </span>
                    <span className="text-gray-400 text-lg">/mo</span>
                  </>
                ) : (
                  <>
                    <span className="text-4xl font-bold text-white">{plan.price}</span>
                    {plan.price !== 'Custom' && (
                      <span className="text-gray-400 text-lg">/mo</span>
                    )}
                  </>
                )}
              </div>
              <p className="text-gray-400">{plan.description}</p>
            </div>

            <ul className="space-y-4 mb-8">
              {plan.features.map((feature, idx) => (
                <motion.li 
                  key={idx}
                  className="flex items-center gap-3 text-gray-300"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 + idx * 0.1 }}
                >
                  <FiCheck className="text-purple-400 flex-shrink-0" />
                  <span>{feature}</span>
                </motion.li>
              ))}
            </ul>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleSubscriptionChange(plan.id)}
              className={`w-full py-3 rounded-lg font-medium transition-colors ${
                plan.id === usage?.subscription?.tier
                  ? 'bg-gray-600 cursor-default'
                  : plan.popular
                  ? 'bg-darker-gradient hover:bg-alternative-gradient text-white'
                  : 'bg-purple-600/20 text-purple-400 hover:bg-purple-600/30'
              }`}
              disabled={plan.id === usage?.subscription?.tier}
            >
              {plan.id === usage?.subscription?.tier
                ? 'Current Plan'
                : plan.id === 'enterprise'
                ? 'Contact Sales'
                : usage?.subscription
                ? 'Change Plan'
                : 'Get Started'}
            </motion.button>
          </motion.div>
        ))}
      </motion.div>
    </div>
  </section>
  );

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#1a0b26] flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1a0b26]">
      {/* Pricing Plans - Always visible */}
      <motion.div>
        {renderPricingPlans()}
      </motion.div>

      {/* Subscription Manager - Only if subscribed */}
      {usage?.subscription && (
        <motion.div>
          {renderSubscriptionManager()}
        </motion.div>
      )}

      {/* FAQ Section - Always visible */}
      <motion.div 
        className="mt-20 max-w-3xl mx-auto px-4 pb-20"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <h3 className="text-2xl font-bold text-center mb-8 text-white">
          Frequently Asked Questions
        </h3>
        <div className="space-y-4">
          {[
            {
              question: "How does the billing cycle work?",
              answer: "Your subscription starts the day you upgrade and renews monthly. You can cancel or change plans at any time."
            },
            {
              question: "Can I change plans later?",
              answer: "Yes! You can upgrade or downgrade your plan at any time. Changes take effect immediately."
            },
            {
              question: "What payment methods do you accept?",
              answer: "We accept all major credit cards, PayPal, and bank transfers for enterprise plans."
            }
          ].map((faq, index) => (
            <motion.div
              key={index}
              className="bg-[#2a1b3d] p-6 rounded-xl"
              whileHover={{ scale: 1.01 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h4 className="text-lg font-medium text-white mb-2">{faq.question}</h4>
              <p className="text-gray-400">{faq.answer}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default SubscriptionPage;