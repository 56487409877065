// src/components/NewYTAccountModal.tsx
import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { createYouTubeCredential } from '../../../api/flowsService';

interface NewYTAccountModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const NewYTAccountModal: React.FC<NewYTAccountModalProps> = ({ onClose, onSuccess }) => {
  const [error, setError] = useState<string | null>(null);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await fetch(
          'https://www.googleapis.com/youtube/v3/channels?part=snippet&mine=true',
          {
            headers: {
              'Authorization': `Bearer ${tokenResponse.access_token}`,
              'Accept': 'application/json'
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch YouTube channel');
        }

        const data = await response.json();
        if (!data.items?.length) {
          throw new Error('No YouTube channel found');
        }

        const channel = data.items[0];
        await createYouTubeCredential({
          channel_name: channel.snippet.title,
          access_token: tokenResponse.access_token,
          refresh_token: (tokenResponse as any).refresh_token || '',
          token_expiry: new Date(Date.now() + (tokenResponse.expires_in * 1000)).toISOString()
        });

        onSuccess();
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Authentication failed');
      }
    },
    onError: () => setError('Authentication failed'),
    scope: 'https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube',
    flow: 'implicit'
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg w-96">
        <div className="flex flex-col items-center space-y-4">
          <button 
            onClick={() => login()}
            className="bg-white text-gray-800 px-4 py-2 rounded-lg flex items-center space-x-2"
          >
            Connect YouTube Account
          </button>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default NewYTAccountModal;