import React, { useState } from 'react';
import { Flow } from '../../types/flowTypes';
import { FaRobot, FaClock, FaExclamationCircle, FaCheckCircle, FaTimes } from 'react-icons/fa';
import { Modal } from './common/Modal';
import { getFlowStatusForDate, getAllExecutionTimes } from './utils/flowUtils';
import { TimeBlockData } from './utils/flowUtils';

interface ActionTileProps {
  flow: Flow;
  timeBlockData: TimeBlockData;
  executionTimes: string[];
  date: Date;
}

const getRandomColor = (seed: string) => {
  const colors = [
    'rgb(99, 102, 241)',   // Indigo
    'rgb(236, 72, 153)',   // Pink
    'rgb(34, 197, 94)',    // Green
    'rgb(234, 179, 8)',    // Yellow
    'rgb(249, 115, 22)',   // Orange
    'rgb(168, 85, 247)',   // Purple
    'rgb(14, 165, 233)',   // Sky
    'rgb(244, 63, 94)',    // Rose
    'rgb(45, 212, 191)',   // Teal
    'rgb(132, 204, 22)',   // Lime
    'rgb(217, 70, 239)',   // Fuchsia
    'rgb(251, 146, 60)'    // Orange lighter
  ];
  
  const hash = seed.split('').reduce((acc, char, i) => {
    return char.charCodeAt(0) + ((acc << 5) - acc) + i;
  }, 0);
  
  return colors[Math.abs(hash) % colors.length];
};

const formatBlockExecution = (block: any) => {
  const blockTypeLabels: { [key: string]: string } = {
    email_check: 'Email Check',
    send_email: 'Send Email',
    generate_video: 'Video Generation',
    publish_yt_shorts: 'YouTube Upload',
    publish_x: 'X Post',
    time: 'Time Check'
  };

  const formatData = (data: any) => {
    if (!data) return null;
    
    const results: string[] = [];
    
    // Format based on block type
    switch (block.block_type) {
      case 'email_check':
        if (data.connections?.text) {
          const emailCount = data.connections.text.length;
          results.push(`Found ${emailCount} matching email${emailCount !== 1 ? 's' : ''}`);
        }
        break;
      case 'send_email':
        const recipients = data.settings?.recipients?.length || 0;
        results.push(`Email sent to ${recipients} recipient${recipients !== 1 ? 's' : ''}`);
        break;
      case 'generate_video':
        results.push('Video generated successfully');
        break;
      case 'publish_yt_shorts':
        if (data.connections?.text?.[0]?.body) {
          results.push(`Video published: ${data.connections.text[0].body}`);
        }
        break;
      // Add more block types as needed
    }
    
    return results.join('. ');
  };

  return {
    type: blockTypeLabels[block.block_type] || block.block_type,
    time: new Date(block.timestamp).toLocaleString(),
    details: formatData(block.data)
  };
};

const formatErrorMessage = (error: string) => {
  // Handle RetryError messages
  if (error.includes('RetryError')) {
    return 'Failed to connect after multiple attempts';
  }
  
  // Handle authentication errors
  if (error.includes('AUTHENTICATIONFAILED')) {
    return 'Invalid email credentials';
  }
  
  // Remove technical details and clean up the message
  return error
    .replace(/\[.*?\]/g, '')  // Remove bracketed content
    .replace(/Error:/i, '')   // Remove "Error:" prefix
    .replace(/\{.*?\}/g, '')  // Remove JSON objects
    .trim();
};

const ActionTile: React.FC<ActionTileProps> = ({ flow, timeBlockData, executionTimes, date }) => {
  const [showModal, setShowModal] = useState(false);
  const backgroundColor = getRandomColor(flow.name);

  const getScheduleDisplay = () => {
    switch (timeBlockData.trigger_type) {
      case 'specific_dates':
        return 'Specific dates';
      case 'times_of_day':
        return `Daily at ${timeBlockData.times_of_day?.join(', ')}`;
      case 'delay':
        return `Every ${timeBlockData.delay} minutes`;
      case 'cron_expression':
        return 'Custom schedule';
      default:
        return 'Unknown schedule';
    }
  };

  const { hasErrors, hasSuccess, isLatestSuccessful } = getFlowStatusForDate(flow, date);
  // console.log("following is the flow status for date: ", date);
  // console.log(hasErrors, hasSuccess);
  const lastUpdate = flow.updated_at ? new Date(flow.updated_at).toLocaleString() : 'Never';

  const formatErrorLog = (log: any) => {
    try {
      // If log is a string, try to parse it as JSON
      const parsedLog = typeof log === 'string' ? JSON.parse(log) : log;
      
      // Extract the error message and timestamp
      const timestamp = new Date(parsedLog.timestamp).toLocaleString();
      const message = parsedLog.error_message?.replace(/^"(.+)"$/, '$1') || 'Unknown error';
      
      return { message, timestamp };
    } catch (e) {
      // If parsing fails, return the raw message
      return {
        message: typeof log === 'string' ? log : 'Unknown error',
        timestamp: new Date().toLocaleString()
      };
    }
  };

  const displayedExecutionTimes = timeBlockData.trigger_type === 'delay' 
    ? getAllExecutionTimes(timeBlockData, flow)
    : executionTimes;

  return (
    <>
      <div 
        className="group relative px-3 py-2 rounded-lg cursor-pointer transition-all duration-200 hover:transform hover:scale-[1.02]"
        style={{ backgroundColor: `${backgroundColor}20`, border: `1px solid ${backgroundColor}40` }}
        onClick={() => setShowModal(true)}
      >
        <div className="flex items-center gap-2">
          <div className="flex gap-1 text-sm" style={{ color: backgroundColor }}>
            <FaRobot />
            {hasErrors && !isLatestSuccessful && <FaExclamationCircle className="text-red-500" />}
            {isLatestSuccessful && <FaCheckCircle className="text-green-500" />}
          </div>
          <div className="flex-1 min-w-0">
            <div className="font-medium text-sm truncate" style={{ color: backgroundColor }}>
              {flow.name}
            </div>
            <div className="text-xs opacity-75 truncate flex items-center gap-1">
              <span>{getScheduleDisplay()}</span>
              <span className="text-xs opacity-50">•</span>
              <span className="text-xs opacity-50">
                {flow.updated_at ? new Date(flow.updated_at).toLocaleString() : 'Never'}
              </span>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="space-y-4 text-gray-100 relative">
            <button 
              onClick={() => setShowModal(false)}
              className="absolute top-0 right-0 p-2 text-gray-400 hover:text-red-400 transition-colors"
            >
              <FaTimes className="text-xl" />
            </button>

            <div className="flex items-center gap-3">
              <div className="text-2xl" style={{ color: backgroundColor }}>
                <FaRobot />
              </div>
              <div>
                <h2 className="text-xl font-bold text-transparent bg-clip-text bg-lighter-gradient">
                  {flow.name}
                </h2>
                <p className="text-cadet-blue">{flow.description}</p>
              </div>
            </div>

            <div className="bg-gray-800/80 rounded-lg p-4 border border-gray-700">
              <h3 className="font-semibold text-sm text-dodger-blue">Schedule</h3>
              <p className="mt-1 text-sm text-gray-300">{getScheduleDisplay()}</p>
              
              <h3 className="font-semibold text-sm text-dodger-blue mt-3">Upcoming Executions</h3>
              <div className="mt-2 overflow-x-auto">
                <div className="flex gap-2 pb-2">
                  {displayedExecutionTimes.map((time, index) => (
                    <span key={index}
                      className="px-3 py-1.5 rounded-full whitespace-nowrap text-sm"
                      style={{ backgroundColor: `${backgroundColor}20`, color: backgroundColor }}
                    >
                      {time}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {hasSuccess && (
              <div className="bg-gray-800/50 rounded-lg p-4 border border-dark-sea-green/30">
                <h3 className="font-semibold text-sm text-dark-sea-green mb-3 sticky top-0 bg-gray-800/95 py-2 -mt-2 -mx-4 px-4 z-10 flex items-center gap-2">
                  {isLatestSuccessful ? <FaCheckCircle /> : <FaClock />}
                  Execution History
                </h3>
                <div className="space-y-4 overflow-y-auto max-h-[50vh]">
                  {flow.success_log?.filter(run => {
                    const runDate = new Date(run.start_time);
                    return runDate.getFullYear() === date.getFullYear() &&
                           runDate.getMonth() === date.getMonth() &&
                           runDate.getDate() === date.getDate();
                  }).slice(-5).reverse().map((run, runIndex) => (
                    <div key={runIndex} className="bg-gray-800/50 rounded-lg p-3 space-y-2">
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-dodger-blue font-medium">
                          Run {new Date(run.start_time).toLocaleString()}
                        </span>
                        <span className={`px-2 py-0.5 rounded-full text-xs flex items-center gap-1 ${
                          run.status === 'completed' 
                            ? 'bg-dark-sea-green/20 text-dark-sea-green' 
                            : 'bg-indian-red/20 text-indian-red'
                        }`}>
                          {run.status === 'completed' ? <FaCheckCircle className="text-xs" /> : <FaTimes className="text-xs" />}
                          {run.status === 'completed' ? 'Completed' : 'Failed'}
                        </span>
                      </div>
                      
                      <div className="space-y-2">
                        {run.blocks.filter((block: any) => block.block_type !== 'time').map((block: any, blockIndex: number) => {
                          const { type, time, details } = formatBlockExecution(block);
                          return (
                            <div key={blockIndex} className="bg-gray-900/30 rounded p-2 text-sm">
                              <div className="flex items-center gap-2">
                                <span className="text-gray-300">{type}</span>
                                <span className="text-xs text-gray-500">•</span>
                                <span className="text-xs text-gray-400">{time}</span>
                              </div>
                              {details && (
                                <div className="mt-1 text-xs text-gray-400">
                                  {details}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {hasErrors && (
              <div className="bg-gray-800/50 rounded-lg p-4 border border-indian-red/30">
                <h3 className="font-semibold text-sm text-indian-red mb-3 sticky top-0 bg-gray-800/95 py-2 -mt-2 -mx-4 px-4 z-10 flex items-center gap-2">
                  <FaExclamationCircle />
                  Recent Errors
                </h3>
                <div className="space-y-2 overflow-y-auto max-h-[30vh]">
                  {flow.error_log?.filter(log => {
                    try {
                      const errorData = typeof log === 'string' ? JSON.parse(log) : log;
                      const logDate = new Date(errorData.timestamp);
                      return logDate.getFullYear() === date.getFullYear() &&
                             logDate.getMonth() === date.getMonth() &&
                             logDate.getDate() === date.getDate();
                    } catch (e) {
                      return false;
                    }
                  }).slice(-5).reverse().map((log, index) => {
                    const { message, timestamp } = formatErrorLog(log);
                    const formattedMessage = formatErrorMessage(message);
                    
                    return (
                      <div key={index} className="text-sm bg-firebrick/10 p-3 rounded">
                        <div className="flex items-start gap-2">
                          <FaExclamationCircle className="text-indian-red mt-1 flex-shrink-0" />
                          <div className="flex-1">
                            <div className="text-orange-red font-medium">
                              {formattedMessage}
                            </div>
                            <div className="text-xs text-indian-red/60 mt-1 flex items-center gap-2">
                              <FaClock className="flex-shrink-0" />
                              {new Date(timestamp).toLocaleTimeString()}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {!hasErrors && !hasSuccess && (
              <div className="text-center text-gray-400 italic bg-gray-800/30 p-4 rounded-lg">
                No events yet - your flow is ready to run! ✨
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActionTile;