import axiosInstance from './axios';
import { UsageData, SubscriptionData, PaymentMethod, StripeConfig } from '../types/subscriptionTypes';

const subscriptionService = {
  // Get current subscription details
  getCurrentSubscription: async (): Promise<SubscriptionData> => {
    const response = await axiosInstance.get('/api/subscription/');
    return response.data;
  },

  // Get current usage statistics
  getUsageStats: async (): Promise<UsageData> => {
    const response = await axiosInstance.get('/api/subscription/usage/');
    return response.data;
  },

  // Create checkout session
  createCheckoutSession: async (
    planId: string, 
    isNewSubscription: boolean = false,
    billingPeriod: 'monthly' | 'yearly' = 'monthly'
  ): Promise<{ sessionId: string }> => {
    const response = await axiosInstance.post('/api/subscription/create-checkout-session/', {
      plan_id: planId,
      billing_period: billingPeriod,
      is_change: !isNewSubscription
    });
    return response.data;
  },

  // Cancel subscription
  cancelSubscription: async (): Promise<{ status: string }> => {
    const response = await axiosInstance.post('/api/subscription/cancel/');
    return response.data;
  },

  // Get payment methods
  getPaymentMethods: async (): Promise<PaymentMethod[]> => {
    const response = await axiosInstance.get('/api/subscription/payment-methods/');
    return response.data;
  },

  // Get payment history
  getPaymentHistory: async () => {
    const response = await axiosInstance.get('/api/subscription/payment-history/');
    return response.data;
  },

  // Get Stripe configuration
  getStripeConfig: async (): Promise<StripeConfig> => {
    const response = await axiosInstance.get('/api/stripe-config/');
    return response.data;
  }
};

export default subscriptionService;
