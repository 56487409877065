// src/components/ProjectManager/AdvancedView.tsx
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  Edge as ReactFlowEdge,
  Node as ReactFlowNode,
} from "react-flow-renderer";
import { Flow, Block, Connection } from "../../../types/flowTypes";
import FlowManager from "./FlowManager";
import {
  addFlow,
  modifyFlow,
  removeFlow,
} from "../../../features/flows/flowsSlice";
import { addBlock, removeBlock } from "../../../features/flows/blocksSlice";
import {
  addConnection,
  removeConnection,
} from "../../../features/flows/connectionsSlice";
import { motion } from "framer-motion";
import { fetchUsageStats, selectUsage } from "../../../features/user/subscriptionSlice";
import fetchUsage from "../../../features/user/subscriptionSlice";
import { useNavigate } from 'react-router-dom';

const blockTypeMapping: { [key: string]: string } = {
  "Time Trigger": "time",
  "Check Email": "email_check",
  "Send Email": "send_email",
  "Generate Video": "generate_video",
  "Publish YT Shorts": "publish_yt_shorts",
  "Publish on X": "publish_x",
};

interface AdvancedViewProps {
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  setNodes: React.Dispatch<React.SetStateAction<ReactFlowNode[]>>;
  setEdges: React.Dispatch<React.SetStateAction<ReactFlowEdge[]>>;
  captureThumbnail: (reactFlowInstance: HTMLDivElement) => Promise<string>;
  reactFlowInstance: React.RefObject<any>;
  flows: Flow[];
  blocks: Block[];
  connections: Connection[];
  setTabActionEditor: (reverse:boolean) => void;
  setSelectedFlow: React.Dispatch<React.SetStateAction<string | null>>;
}

const AdvancedView: React.FC<AdvancedViewProps> = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  captureThumbnail,
  reactFlowInstance,
  flows,
  blocks,
  connections,
  setTabActionEditor,
  setSelectedFlow,
}) => {
  const dispatch = useAppDispatch();
  const usage = useAppSelector(selectUsage);
  const [loadingFlowId, setLoadingFlowId] = useState<string | null>(null);
  const limitReachedMessage = "Flow limit reached. Please delete existing flows or upgrade your subscription plan to create more.";
  const navigate = useNavigate();

  const convertNodesToBlocks = (
    flowId: string,
    nodes: ReactFlowNode[]
  ): Block[] => {
    return nodes.map((node) => ({
      // id: node.id,
      flow: flowId,
      block_type: node.type ? blockTypeMapping[node.type] : "undefined",
      position_x: node.position.x,
      position_y: node.position.y,
      data: [...node.data, "label:node.id"],
    }));
  };

  const convertEdgesToConnections = (
    blocks: Block[],
    edges: ReactFlowEdge[]
  ): Connection[] => {
    return edges.map((edge) => ({
      id: edge.id,
      flow: blocks[0]?.flow || "",
      source_label: blocks.find((block) => block.id === edge.source)?.id || "",
      target_label: blocks.find((block) => block.id === edge.target)?.id || "",
      connection_type: edge.sourceHandle || "",
    }));
  };

  const saveProject = async () => {
    if (usage && usage.action_flows.used >= usage.action_flows.limit) {
      alert("Flow limit reached. Please upgrade your plan to create more flows.");
      return;
    }
    const projectName = window.prompt(
      "Enter the project name:",
      `Project_${nodes.length + 1}`
    );
    if (!projectName) return;

    if (reactFlowInstance.current) {
      const thumbnail: string = ""; //await captureThumbnail(reactFlowInstance.current);
      const newFlow: Flow = {
        name: projectName,
        description: "A new automation project.",
        thumbnail,
        status: "saved",
      };

      const responseFlow = await dispatch(addFlow(newFlow)).unwrap();
      const updatedBlocks = convertNodesToBlocks(responseFlow.id!, nodes);
      const updatedConnections = convertEdgesToConnections(
        updatedBlocks,
        edges
      );

      // Clear previous blocks and connections(deleting blocks deletes connections)
      blocks
        .filter((block) => block.flow === responseFlow.id)
        .forEach((block) => dispatch(removeBlock(block.id!)));

      // Add new blocks and connections
      updatedBlocks.forEach((block) => dispatch(addBlock(block)));
      updatedConnections.forEach((connection) =>
        dispatch(addConnection(connection))
      );

      setNodes([]);
      setEdges([]);
    }
    await dispatch(fetchUsageStats()); // Refresh usage after saving
  };

  const loadFlow = (flowId: string) => {
    console.log(`loading flow ${flowId}`);
    FlowManager.loadProject(
      flowId,
      flows,
      blocks,
      connections,
      setNodes,
      setEdges
    );
    setSelectedFlow(flowId);
    setTabActionEditor(false);
  };

  const updateFlowDetails = (flowId: string, newName: string, newDescription: string) => {
    const updatedFlow = flows.find((f) => f.id === flowId);
    if (updatedFlow) {
      dispatch(
        modifyFlow({
          id: flowId,
          updatedFlow: { ...updatedFlow, name: newName, description: newDescription },
        })
      );
    }
  };
  
  const handleFlowDetailsChange = (flowId: string) => {
    const updatedFlow = flows.find((f) => f.id === flowId);
    if (updatedFlow) {
      const newName = window.prompt("Enter new flow name:", updatedFlow.name);
      const newDescription = window.prompt("Enter new flow description:", updatedFlow.description || "");
      
      if (newName && newDescription) {
        updateFlowDetails(flowId, newName, newDescription);
      }
    }
  };

  const deleteFlow = async (flowId: string) => {
    const flowToDelete = flows.find((f) => f.id === flowId);
    if (flowToDelete) {
      const confirmation = window.confirm(
        `Are you sure you want to delete flow "${flowToDelete.name}"?`
      );
      if (confirmation) {
        setLoadingFlowId(flowId);
        await dispatch(removeFlow(flowId));
        // Remove all blocks and connections related to the flow
        // blocks
        //   .filter((block) => block.flow === flowId)
        //   .forEach((block) => dispatch(removeBlock(block.id!)));

        // connections
        //   .filter(
        //     (connection) =>
        //       connection.flow === flowId &&
        //       blocks.some((block) => block.id === connection.source_block) &&
        //       blocks.some((block) => block.id === connection.target_block)
        //   )
        //   .forEach((connection) => dispatch(removeConnection(connection.id!)));

        // Clear nodes and edges if the current flow is the one being deleted
        if (FlowManager.getSelectedFlowId() === flowId) {
          setNodes([]);
          setEdges([]);
        }
        setLoadingFlowId(null);
        await dispatch(fetchUsageStats()); // Refresh usage after deletion
      }
    }
  };

  return (
    <div className="flex flex-col items-center mb-8 bg-transparent mt-20 w-full">
      <motion.div
        className="text-center mb-8"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-default-gradient">Manage Your Flows</h2>
      </motion.div>
      <div className="relative group">
        <button
          className={`px-4 py-2 ${usage && usage.action_flows.used >= usage.action_flows.limit ? 'bg-gray-500 hover:bg-gray-600' : 'bg-green-600'} text-white rounded-lg mb-4 transition-colors`}
          onClick={() => usage && usage.action_flows.used >= usage.action_flows.limit ? navigate('/subscription') : setTabActionEditor(false)}
        >
          Create New Flow
        </button>
        {usage && usage.action_flows.used >= usage.action_flows.limit && (
          <div 
            className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap cursor-pointer"
            onClick={() => navigate('/subscription')}
          >
            {limitReachedMessage} Click to upgrade.
          </div>
        )}
      </div>
      <ul className="w-full space-y-4">
        {flows.map((flow) => (
          <div key={flow.id} className="flex flex-col bg-gray-700 p-4 rounded-lg w-full">
            <div className="flex flex-col sm:flex-row justify-between sm:items-center w-full">
              <div className="flex flex-col sm:flex-row sm:items-center mb-4 sm:mb-0">
                <div className="flex flex-col">
                  <span className="text-xl font-semibold text-white">
                    {flow.name}
                  </span>
                  <p className="text-sm text-gray-300">
                    {flow.description || "No description available."}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-2">
                <button
                  className={`px-4 py-2 rounded-lg ${
                    flow.id === FlowManager.getSelectedFlowId()
                      ? "bg-blue-600"
                      : "bg-gray-600"
                  } text-white`}
                  onClick={() => loadFlow(flow.id!)}
                >
                  Edit flow
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                  onClick={() => handleFlowDetailsChange(flow.id!)}
                >
                  Rename
                </button>
                <button
                  className={`px-4 py-2 bg-red-500 text-white rounded-lg relative ${
                    loadingFlowId === flow.id ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => deleteFlow(flow.id!)}
                  disabled={loadingFlowId === flow.id}
                >
                  {loadingFlowId === flow.id ? (
                    <span className="absolute inset-0 flex items-center justify-center">
                      <svg
                        className="w-5 h-5 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default AdvancedView;
