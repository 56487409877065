// src/utils/connectionValidation.ts
export const isConnectionValid = (
  sourceId: string,
  sourceHandle: string,
  targetId: string,
  targetHandle: string
): { valid: boolean; message?: string } => {
  // const allowedConnections: Record<string, Record<string, Record<string, string[]>>> = {
  //   "Time Trigger": {
  //     out: {
  //       "trigger": ["Check Email", "Send Email", "Generate Video", "Publish YT Shorts", "Publish on X"],
  //     },
  //   },
  //   "Check Email": {
  //     out: {
  //       received: ["Send Email", "Generate Video", "Publish YT Shorts", "Publish on X"],
  //       email: ["Send Email"],
  //       content: ["Send Email"],
  //       files: ["Send Email"],
  //     },
  //     in: {
  //       trigger: ["Time Trigger"],
  //     },
  //   },
  //   "Send Email": {
  //     out: {
  //       sent: ["Generate Video", "Publish YT Shorts", "Publish on X"],
  //       "failed to send": ["Generate Video", "Publish YT Shorts", "Publish on X"],
  //     },
  //     in: {
  //       trigger: ["Time Trigger", "Check Email"],
  //       email: [],
  //       content: [],
  //       file: [],
  //     },
  //   },
  //   "Generate Video": {
  //     out: {
  //       completed: ["Publish YT Shorts", "Publish on X"],
  //       file: ["Publish YT Shorts", "Publish on X"],
  //       failed: ["Publish YT Shorts", "Publish on X"],
  //     },
  //     in: {
  //       trigger: ["Time Trigger", "Check Email", "Send Email"],
  //       prompt: [],
  //     },
  //   },
  //   "Publish YT Shorts": {
  //     out: {
  //       published: [],
  //       error: [],
  //     },
  //     in: {
  //       trigger: ["Time Trigger", "Check Email", "Send Email", "Generate Video"],
  //       title: [],
  //       description: [],
  //       file: ["Generate Video"],
  //     },
  //   },
  //   "Publish on X": {
  //     out: {
  //       published: [],
  //       error: [],
  //     },
  //     in: {
  //       trigger: ["Time Trigger", "Check Email", "Send Email", "Generate Video"],
  //       content: [],
  //       media: ["Generate Video"],
  //     },
  //   },
  // };

  const sourceType = getNodeType(sourceId);
  const targetType = getNodeType(targetId);

  // console.warn("debug chain:");
  // console.log(sourceType);
  // console.log(sourceHandle);
  // console.log(targetHandle);
  // console.log(sourceHandle.split("-")[0]);
  // console.log(targetHandle.split("-")[0]);
  // console.log(allowedConnections[sourceType]);
  // console.log(allowedConnections[sourceType]?.['out']?.[sourceHandle]);

  // const sourceToTargetValid =
  //   allowedConnections[sourceType]?.["out"]?.[sourceHandle]?.includes(targetType) ?? false;
  // const targetToSourceValid =
  //   allowedConnections[targetType]?.["in"]?.[targetHandle]?.includes(sourceType) ?? false;

  // const valid = sourceToTargetValid && targetToSourceValid;
  let valid;
  if(sourceHandle.split("-")[0]==='error'){
    valid = targetHandle.split("-")[0]==='signal'
  }else{
    valid = sourceHandle.split("-")[0] === targetHandle.split("-")[0];
  };

  console.log(`connection valid: ${valid}`);

  const message = valid
    ? undefined
    : `Connection not allowed between ${sourceType}-${sourceHandle} and ${targetType}-${targetHandle}`;

  return { valid, message };
};

const getNodeType = (id: string): string => {
  // Example implementation: Extract node type by splitting the ID
  // console.log(`node type: ${id.split("-")[0].replace(/([A-Z])/g, "$1").trim()}`)
  return id.split("-")[0].replace(/([A-Z])/g, "$1").trim();
};