import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UsageData, SubscriptionData } from '../../types/subscriptionTypes';
import subscriptionService from '../../api/subscriptionService';

// Define the state interface
interface SubscriptionState {
  usage: UsageData | null;
  subscription: SubscriptionData | null;
  isLoading: boolean;
  error: string | null;
  lastUpdated: number | null;
  refreshIntervalId: NodeJS.Timeout | null;
}

// Initial state
const initialState: SubscriptionState = {
  usage: null,
  subscription: null,
  isLoading: false,
  error: null,
  lastUpdated: null,
  refreshIntervalId: null,
};

// Async thunks
export const fetchUsageStats = createAsyncThunk(
  'subscription/fetchUsageStats',
  async (_, { rejectWithValue }) => {
    try {
      const usageData = await subscriptionService.getUsageStats();
      return usageData;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch usage stats');
    }
  }
);

export const fetchSubscriptionDetails = createAsyncThunk(
  'subscription/fetchSubscriptionDetails',
  async (_, { rejectWithValue }) => {
    try {
      const subscriptionData = await subscriptionService.getCurrentSubscription();
      return subscriptionData;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch subscription details');
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancelSubscription',
  async (_, { rejectWithValue }) => {
    try {
      await subscriptionService.cancelSubscription();
      return null;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to cancel subscription');
    }
  }
);

// Add new thunk for initializing data
export const initializeSubscriptionData = createAsyncThunk(
  'subscription/initialize',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(fetchUsageStats()),
      dispatch(fetchSubscriptionDetails())
    ]);
  }
);

// Add refresh interval thunk
export const startSubscriptionRefresh = createAsyncThunk(
  'subscription/startRefresh',
  async (_, { dispatch }) => {
    // Initial fetch
    await dispatch(initializeSubscriptionData());

    // Set up periodic refresh
    const refreshInterval: NodeJS.Timeout = setInterval(() => {
      dispatch(initializeSubscriptionData());
    }, 5 * 60 * 1000); // Refresh every 5 minutes

    // Return interval ID for cleanup
    return refreshInterval;
  }
);

// Add stop refresh thunk
export const stopSubscriptionRefresh = createAsyncThunk(
  'subscription/stopRefresh',
  async (intervalId: NodeJS.Timeout) => {
    clearInterval(intervalId);
  }
);

// Create the slice
export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // Update specific usage metrics when actions are performed
    incrementNotificationCount: (state, action) => {
      if (state.usage?.notifications) {
        const { channel, count = 1 } = action.payload;
        if (channel === 'browser' || channel === 'push' || channel === 'email') {
          state.usage.notifications.by_channel.standard[channel as keyof typeof state.usage.notifications.by_channel.standard] += count;
        } else if (channel === 'premium') {
          state.usage.notifications.by_channel.premium += count;
        } else if (channel === 'social') {
          state.usage.notifications.by_channel.social += count;
        }
        state.usage.notifications.used += count;
        // Update percentage
        if (state.usage.notifications.limit > 0) {
          state.usage.notifications.percentage = 
            (state.usage.notifications.used / state.usage.notifications.limit) * 100;
        }
      }
    },
    incrementAlertCount: (state, action) => {
      if (state.usage?.alerts) {
        const { type } = action.payload;
        if (type in state.usage.alerts.by_type) {
          state.usage.alerts.by_type[type as keyof typeof state.usage.alerts.by_type] += 1;
          state.usage.alerts.active += 1;
          // Update percentage
          if (state.usage.alerts.limit > 0) {
            state.usage.alerts.percentage = 
              (state.usage.alerts.active / state.usage.alerts.limit) * 100;
          }
        }
      }
    },
    decrementAlertCount: (state, action) => {
      if (state.usage?.alerts) {
        const { type } = action.payload;
        if (type in state.usage.alerts.by_type) {
          state.usage.alerts.by_type[type as keyof typeof state.usage.alerts.by_type] -= 1;
          state.usage.alerts.active -= 1;
          // Update percentage
          if (state.usage.alerts.limit > 0) {
            state.usage.alerts.percentage = 
              (state.usage.alerts.active / state.usage.alerts.limit) * 100;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchUsageStats
      .addCase(fetchUsageStats.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsageStats.fulfilled, (state, action) => {
        state.usage = action.payload;
        state.isLoading = false;
        state.lastUpdated = Date.now();
      })
      .addCase(fetchUsageStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Handle fetchSubscriptionDetails
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      // Handle cancelSubscription
      .addCase(cancelSubscription.fulfilled, (state) => {
        if (state.subscription) {
          state.subscription.status = 'canceled';
          state.subscription.cancel_at_period_end = true;
        }
      })
      // Handle refresh interval
      .addCase(startSubscriptionRefresh.fulfilled, (state, action) => {
        state.refreshIntervalId = action.payload as NodeJS.Timeout;
      })
      .addCase(stopSubscriptionRefresh.fulfilled, (state) => {
        state.refreshIntervalId = null;
      });
  },
});

// Export actions
export const {
  incrementNotificationCount,
  incrementAlertCount,
  decrementAlertCount,
} = subscriptionSlice.actions;

// Export selectors
export const selectUsage = (state: { subscription: SubscriptionState }) => state.subscription.usage;
export const selectSubscription = (state: { subscription: SubscriptionState }) => 
  state.subscription.subscription;
export const selectIsLoading = (state: { subscription: SubscriptionState }) => 
  state.subscription.isLoading;
export const selectError = (state: { subscription: SubscriptionState }) => state.subscription.error;
export const selectLastUpdated = (state: { subscription: SubscriptionState }) => 
  state.subscription.lastUpdated;

// Helper selectors for specific limits
export const selectNotificationLimit = (state: { subscription: SubscriptionState }) => 
  state.subscription.usage?.notifications.limit;
export const selectAlertLimit = (state: { subscription: SubscriptionState }) => 
  state.subscription.usage?.alerts.limit;
export const selectActionFlowLimit = (state: { subscription: SubscriptionState }) => 
  state.subscription.usage?.action_flows.limit;

export const selectRefreshIntervalId = (state: { subscription: SubscriptionState }) => 
  state.subscription.refreshIntervalId;

export default subscriptionSlice.reducer;
