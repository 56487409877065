import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  RiNewspaperLine,
  RiPriceTag3Line,
  RiListUnordered,
  RiInformationLine,
  RiCloudFill,
  RiFootballFill,
  RiTwitterXFill,
  RiCalendarEventFill,
} from "react-icons/ri";
import AnimatedModalContainer from "./forms/components/AnimatedModalContainer";

type OptionType =
  | "News by Keyword"
  | "News by Source"
  | "Price Alerts"
  | "New Listings"
  | "Weather"
  | "Sport"
  | "X News"
  | "Event";

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (option: OptionType) => void;
}

const options = [
  { label: "News by Keyword", icon: <RiNewspaperLine size={30} /> },
  { label: "News by Source", icon: <RiListUnordered size={30} /> },
  { label: "Price Alerts", icon: <RiPriceTag3Line size={30} /> },
  { label: "New Listings", icon: <RiInformationLine size={30} /> },
  { label: "Weather", icon: <RiCloudFill size={30} /> },
  { label: "Sport", icon: <RiFootballFill size={30} /> },
  { label: "X News", icon: <RiTwitterXFill size={30} /> },
  { label: "Event", icon: <RiCalendarEventFill size={30} /> },
];

const CategoryModal: React.FC<CategoryModalProps> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const handleSelect = (option: OptionType) => {
    onSelect(option);
  };

  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <h2 className="text-4xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient text-center">
        Select Category
      </h2>
      <div className="grid grid-cols-2 gap-6">
        {options.map(({ label, icon }) => (
          <motion.div
            key={label}
            className="cursor-pointer backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 p-6 flex flex-col items-center justify-center shadow-xl hover:shadow-2xl transition-all duration-300"
            whileHover={{ 
              scale: 1.02,
              backgroundColor: "rgba(255, 255, 255, 0.15)"
            }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleSelect(label as OptionType)}
          >
            <div className="text-3xl text-white/90 mb-3 bg-gradient-to-br from-purple-500 to-pink-500 p-3 rounded-xl">
              {icon}
            </div>
            <div className="text-center text-sm font-medium text-white/80">
              {label}
            </div>
          </motion.div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClose}
          className="px-6 py-2.5 rounded-xl bg-white/10 backdrop-blur-sm border border-white/20 text-white/80 font-medium transition-all duration-300 hover:bg-white/20"
        >
          Cancel
        </motion.button>
      </div>
    </AnimatedModalContainer>
  );
};

export default CategoryModal;
