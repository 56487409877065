import React from "react";
import {
  RiInstagramFill,
  RiTwitterXLine,
  RiMailSendFill,
  RiGithubFill,
  RiDiscordFill,
} from "react-icons/ri";

const Footer: React.FC = () => {
  const socialLinks = [
    {
      icon: <RiInstagramFill />,
      href: "https://www.instagram.com/terrasignalflow/",
      gradient: "from-purple-600 to-pink-500",
    },
    {
      icon: <RiTwitterXLine />,
      href: "https://twitter.com/SignalFlowTerra",
      gradient: "from-blue-400 to-blue-600",
    },
    {
      icon: <RiMailSendFill />,
      href: "mailto:terrasignalflow.protonmail.com",
      gradient: "from-red-500 to-pink-500",
    },
    {
      icon: <RiGithubFill />,
      href: "https://github.com/SignalFlow",
      gradient: "from-gray-600 to-gray-800",
    },
    {
      icon: <RiDiscordFill />,
      href: "https://discord.gg/signalflow",
      gradient: "from-indigo-500 to-purple-500",
    },
  ];

  return (
    <footer className="relative">
      {/* Gradient divider */}
      <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-purple-500 to-transparent" />
      
      {/* Main footer content */}
      <div className="bg-main-gradient backdrop-blur-xl">
        <div className="max-w-7xl mx-auto px-4 py-12">
          
          {/* Top section */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <img
                  src="/favicon.ico"
                  alt="Signal Flow Logo"
                  className="h-8 w-8 animate-pulse"
                />
                <h3 className="text-xl font-bold bg-clip-text text-transparent bg-default-gradient">
                  Signal Flow
                </h3>
              </div>
              <p className="text-sm text-gray-400">
                Empowering your digital presence with real-time notifications and seamless integrations.
              </p>
            </div>
            
            {/* Quick Links */}
            <div className="space-y-4">
              <h4 className="text-white font-semibold">Quick Links</h4>
              <ul className="space-y-2 text-sm text-gray-400">
                <li><a href="/docs" className="hover:text-purple-400 transition-colors">Documentation</a></li>
              </ul>
            </div>
            
            {/* Legal */}
            <div className="space-y-4">
              <h4 className="text-white font-semibold">Legal</h4>
              <ul className="space-y-2 text-sm text-gray-400">
                <li><a href="/privacy" className="hover:text-purple-400 transition-colors">Privacy Policy</a></li>
                <li><a href="/terms" className="hover:text-purple-400 transition-colors">Terms of Service</a></li>
                <li><a href="/cookies" className="hover:text-purple-400 transition-colors">Cookie Policy</a></li>
              </ul>
            </div>
            
            {/* Contact */}
            <div className="space-y-4">
              <h4 className="text-white font-semibold">Connect With Us</h4>
              <div className="flex space-x-4">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`
                      text-2xl transform transition-all duration-300
                      hover:scale-110 hover:-translate-y-1
                      bg-gradient-to-r ${link.gradient}
                      p-2 rounded-lg text-white
                      hover:shadow-lg hover:shadow-purple-500/25
                    `}
                  >
                    {link.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
          
          {/* Bottom section */}
          <div className="pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-sm text-gray-400">
              © 2024 Signal Flow. All rights reserved.
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-xs px-3 py-1 rounded-full bg-purple-500/20 text-purple-400 border border-purple-500/20">
                v0.0.14beta
              </span>
              <span className="text-xs px-3 py-1 rounded-full bg-green-500/20 text-green-400 border border-green-500/20">
                All Systems Operational
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
