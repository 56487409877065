import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../websocket/WebSocketClient';
import { Notification } from '../types/notificationTypes';
import { useAppDispatch, useAppSelector } from '../app/store';
import { fetchNotifications } from '../features/notifications/notificationSlice';
import { markAsViewed, markAllAsViewed } from '../features/notifications/notificationSlice';
import { markAsViewed as markAsViewedService, markAllAsViewed as markAllAsViewedService } from '../api/notificationService';
import { motion } from 'framer-motion';

const NotificationPage: React.FC = () => {
    const { notifications: websocketNotifications } = useContext(NotificationContext);
    const { notifications:_notifications, loading, error } = useAppSelector(state => state.notifications);
    const [notifications, setNotifications] = useState<Notification[]>(_notifications);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    useEffect(()=>{
        setNotifications(_notifications)
      },[_notifications])

    // Merge new websocket notifications into the existing list
    useEffect(() => {
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...websocketNotifications.filter(
                (wsNotification) => !prevNotifications.some((notification) => notification.id === wsNotification.id)
            ),
        ]);
    }, [websocketNotifications]);

    // Mark a single notification as viewed
    // const handleMarkAsViewed = async (id: number) => {
    //     try {
    //         await markAsViewedService(id);
    //         dispatch(markAsViewed(id));
    //     } catch (error) {
    //         console.error(`Error marking notification ${id} as viewed:`, error);
    //     }
    // };

    // Mark all notifications as viewed
    const handleMarkAllAsViewed = async () => {
        try {
            await markAllAsViewedService();
            dispatch(markAllAsViewed());
        } catch (error) {
            console.error('Error marking all notifications as viewed:', error);
        }
    };
    
    // const getColor = async (id: number, type: string): Promise<string> => {
    //     console.log(type);
    //     console.log(id);
    //     try {
    //         if (type === 'crypto_price') {
    //             const alert = await getCryptoPriceChangeAlert(id);
    //             return alert.color;
    //         } else if (type === 'weather') {
    //             const alert = await getWeatherAlert(id);
    //             return alert.color;
    //         } else if (type === 'new_listing') {
    //             const alert = await getNewListingAlert(id);
    //             return alert.color;
    //         } else if (type === 'news') {
    //             const alert = await getNewsAlert(id);
    //             return alert.color;
    //         }
    //     } catch (error) {
    //         console.error('Error fetching color:', error);
    //     }
    //     return 'black'; // Default color if no type matches or on error
    // };

    // const [colors, setColors] = useState<Record<number, string>>({});

    // useEffect(() => {
    //     const fetchColors = async () => {
    //         const colorMap: Record<number, string> = {};
    //         for (const notification of notifications) {
    //             const color = await getColor(notification.alert_id, notification.alert_type);
    //             colorMap[notification.id] = color ? color : 'black';
    //         }
    //         setColors(colorMap);
    //     };

    //     fetchColors();
    // }, [notifications]);

    // Mark notification as viewed after 10 seconds
    // const scheduleMarkAsViewed = (id: number) => {
    //     setTimeout(() => {
    //         handleMarkAsViewed(id);
    //     }, 10000);
    // };

    const shadeColor = (color: string | undefined, percent: number, status: any, default_color = 'white'): string => {
        if (color) {
            if (status === 'sent' || status === 'pending'){
                const [r, g, b] = (color.match(/\w\w/g) || ['0', '0', '0']).map(x => {
                    const num = parseInt(x, 16);
                    return Math.max(0, Math.min(255, Math.round(num * (1 - percent / 100))));
                });
                return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
            }else{return default_color}
        }
        return default_color; // Default color if input color is undefined
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6 pt-20">
            <motion.div
                className="text-center mb-8"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
            >
                <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-default-gradient">Notifications</h2>
            </motion.div>
            <button
                className="mb-4 px-4 py-2 bg-darker-gradient text-white rounded-full shadow hover:bg-blue-600"
                onClick={handleMarkAllAsViewed}
            >
                Mark All as Viewed
            </button>
            <ul className="space-y-4">
                {notifications.map((notification) => (

                    <li key={notification.id}>
                        {(notification.status === 'sent' || notification.status === 'pending') && <p className="text-red-400">New</p>}
                        <a
                            href={notification.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`block relative p-4 rounded-lg shadow-sm min-h-[140px] border-8`}
                            style={{ backgroundColor: notification.alert_object?.color, borderColor: shadeColor(notification.alert_object?.color, 30, notification.status, notification.alert_object?.color) }}
                        >
                            <h3 className={`text-lg font-semibold`}
                                style={{ color: shadeColor(notification.alert_object?.color, 50, notification.status) }}>
                                {notification.title}
                            </h3>
                            <p className="text-sm text-gray-100">{notification.message}</p>
                            {notification.alert_object && (
                                <div className="flex gap-2 mt-2">
                                    <span
                                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                                            notification.alert_object.notification_details.browser
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-300 text-gray-700'
                                        }`}
                                    >
                                        Browser
                                    </span>
                                    <span
                                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                                            notification.alert_object.notification_details.email
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-300 text-gray-700'
                                        }`}
                                    >
                                        Email
                                    </span>
                                    <span
                                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                                            notification.alert_object.notification_details.sms
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-300 text-gray-700'
                                        }`}
                                    >
                                        SMS
                                    </span>
                                    <span
                                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                                            notification.alert_object.notification_details.whatsapp
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-300 text-gray-700'
                                        }`}
                                    >
                                        WhatsApp
                                    </span>
                                </div>
                            )}
                            {/* <small className="absolute bottom-2 right-4 text-xs text-gray-500">
                                Created At: {new Date(notification.created_at).toLocaleString()}
                            </small> */}
                            {notification.sent_at ? (
                                <small className="absolute bottom-6 right-4 text-xs text-gray-200">
                                    Sent At: {new Date(notification.sent_at).toLocaleString()}
                                </small>
                            ):(
                                <small className="absolute bottom-6 right-4 text-xs text-gray-300">
                                Not sent yet
                                </small>
                            )
                            }
                            {notification.viewed_at && (
                                <small className="absolute bottom-2 right-4 text-xs text-gray-200">
                                    Viewed At: {new Date(notification.viewed_at).toLocaleString()}
                                </small>
                            )}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotificationPage;