// src/components/ProjectManager/SimplifiedView.tsx
import React, { useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { addFlow, modifyFlow } from "../../../features/flows/flowsSlice";
import { addBlock, removeBlock } from "../../../features/flows/blocksSlice";
import {
  addConnection,
  removeConnection,
} from "../../../features/flows/connectionsSlice";
import {
  Edge as ReactFlowEdge,
  Node as ReactFlowNode,
} from "react-flow-renderer";
import { Flow, Block, Connection } from "../../../types/flowTypes";
import FlowManager from "./FlowManager";
import { fetchUsageStats, selectUsage } from "../../../features/user/subscriptionSlice";
import fetchUsage from "../../../features/user/subscriptionSlice";
import { useNavigate } from 'react-router-dom';
import { validateFlow } from "../../../components/actions/validators/flowValidators";
import { useToast } from '../../../components/common/Toast/ToastContext';
import { 
  selectSubscription, 
  fetchSubscriptionDetails 
} from '../../../features/user/subscriptionSlice';

const blockTypeMapping: { [key: string]: string } = {
  "Time Trigger": "time",
  "Check Email": "email_check",
  "Send Email": "send_email",
  "Generate Video": "generate_video",
  "Publish YT Shorts": "publish_yt_shorts",
  "Publish on X": "publish_x",
};

interface SimplifiedViewProps {
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  setNodes: React.Dispatch<React.SetStateAction<ReactFlowNode[]>>;
  setEdges: React.Dispatch<React.SetStateAction<ReactFlowEdge[]>>;
  captureThumbnail: (reactFlowInstance: HTMLDivElement) => Promise<string>;
  reactFlowInstance: React.RefObject<any>;
  flows: Flow[];
  blocks: Block[];
  connections: Connection[];
  selectedFlow: string|null;
  setSelectedFlow: React.Dispatch<React.SetStateAction<string|null>>;
  setTabActionEditor: (reverse:boolean)=>void;
}

const limitReachedMessage = "Flow limit reached. Please delete existing flows or upgrade your subscription plan to create more.";

const SimplifiedView: React.FC<SimplifiedViewProps> = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  captureThumbnail,
  reactFlowInstance,
  flows,
  blocks,
  connections,
  selectedFlow,
  setSelectedFlow,
  setTabActionEditor,
}) => {
  const dispatch = useAppDispatch();
  const usage = useAppSelector(selectUsage);
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { showToast } = useToast();

  const convertNodesToBlocks = useCallback(
    (flowId: string, nodes: ReactFlowNode[]): Block[] => {
      return nodes.map((node) => ({
        id: node.id,
        flow: flowId,
        block_type: node.type
          ? blockTypeMapping[node.type].split("-")[0]
          : "undefined",
        position_x: node.position.x,
        position_y: node.position.y,
        data: node.data,
      }));
    },
    []
  );

  const convertEdgesToConnections = useCallback(
    (blocks: Block[], edges: ReactFlowEdge[]): Connection[] => {
      return edges.map((edge) => ({
        id: edge.id,
        flow: blocks[0]?.flow || "",
        source_label:
          blocks.find((block) => block.id === edge.source)?.id || "",
        target_label:
          blocks.find((block) => block.id === edge.target)?.id || "",
        connection_type: edge.sourceHandle?.split("-")[0] || '',
      }));
    },
    []
  );

  const saveFlow = async (asNew = false) => {
    try {
      setIsSaving(true);

      if (asNew && usage && usage.action_flows.used >= usage.action_flows.limit) {
        showToast(limitReachedMessage, 'warning', 'Usage Limit');
        return;
      }

      // Validate blocks before prompting for name
      const blocksToValidate = convertNodesToBlocks(selectedFlow || 'temp', nodes);
      console.log("blocks to validate:"); 
      console.log(blocksToValidate);
      const connectionsToValidate = convertEdgesToConnections(blocksToValidate, edges);
      console.log("connections to validate:");
      console.log(connectionsToValidate);
      const preValidation = validateFlow({ 
        name: "temp", 
        description: "temp", 
        status: "saving" 
      }, blocksToValidate, connectionsToValidate, usage);

      // Handle subscription-related errors first
      if (preValidation.subscriptionErrors?.length) {
        preValidation.subscriptionErrors.forEach(error => {
          showToast(
            error.message, 
            'warning', 
            'Subscription Limit', 
            10000, // longer duration
            () => navigate('/subscription') // onClick handler
          );
        });
        return;
      }

      // Handle regular validation errors
      if (!preValidation.isValid) {
        const errorMessages = [
          ...(preValidation.errors.flow?.filter(err => !err.includes('required')) || []),
          ...preValidation.errors.blocks.map(err => 
            `${err.field} in block ${err.blockId.replace(/-\d+$/, '')}: ${err.message}`
          )
        ];
        
        errorMessages.forEach(message => {
          showToast(message, 'error', 'Validation Error');
        });
        return;
      }

      // Only prompt for name/description if validation passes
      let flowName = asNew || !selectedFlow ? 
        window.prompt("Enter the new flow name:", `Project_${nodes.length + 1}`) : 
        flows.find((flow) => flow.id === selectedFlow)?.name || "New Flow";
      
      if (!flowName) return;

      let flowDescription = asNew || !selectedFlow ?
        window.prompt("Enter new flow description:", "A new automation project.") :
        flows.find((flow) => flow.id === selectedFlow)?.description || "";
      
      if (!flowDescription) return;

      const newFlow: Flow = {
        name: flowName,
        description: flowDescription,
        thumbnail: '',
        status: "saving",
      };

      // Final validation with actual name/description
      const validationResult = validateFlow(
        newFlow, 
        blocksToValidate, 
        connectionsToValidate, 
        usage
      );
      
      if (!validationResult.isValid) {
        const errorMessages = [
          ...(validationResult.errors.flow || []),
          ...validationResult.errors.blocks.map(err => 
            `${err.field} in block ${err.blockId.replace(/-\d+$/, '')}: ${err.message}`
          )
        ];
        
        errorMessages.forEach(message => {
          showToast(message, 'error', 'Validation Error');
        });
        return;
      }

      // Save flow if validation passed
      let responseFlow: Flow;
      if (asNew || !selectedFlow) {
        responseFlow = await dispatch(addFlow(newFlow)).unwrap();
      } else {
        responseFlow = await dispatch(
          modifyFlow({
            id: selectedFlow,
            updatedFlow: { ...newFlow, id: selectedFlow },
          })
        ).unwrap();
      }

        const updatedBlocks = convertNodesToBlocks(responseFlow.id!, nodes);
        console.warn(updatedBlocks);
        const updatedConnections = convertEdgesToConnections(
          updatedBlocks,
          edges
        );
        console.log("connections to save:");
        console.log(updatedConnections);

        // Clear previous blocks and connections(deleting blocks deletes connections)
        blocks
          .filter((block) => block.flow === responseFlow.id)
          .forEach((block) => dispatch(removeBlock(block.id!)));

        // Add updated blocks and connections
        const addBlockPromises = updatedBlocks.map((block) =>
          dispatch(addBlock(block))
        );
        // Wait for all blocks to be added
        await Promise.all([...addBlockPromises])

        const addConnectionPromises = updatedConnections.map((connection) =>
          dispatch(addConnection(connection))
        );
        // Wait for all connections to be added
        await Promise.all([ ...addConnectionPromises]);

        // Change flow status to saved to trigger dag generation
        if (responseFlow.id) {
          newFlow.status = "saved";
          await dispatch(
            modifyFlow({
              id: responseFlow.id,
              updatedFlow: { ...newFlow, id: responseFlow.id },
            })
        ).unwrap();
      }

      setSelectedFlow(responseFlow.id!);
      await dispatch(fetchUsageStats());
      showToast('Flow saved successfully!', 'success', 'Success');
    } catch (error) {
      console.error('Error saving flow:', error);
      showToast('Failed to save flow. Please try again.', 'error', 'Error');
    } finally {
      setIsSaving(false);
    }
  };

  // const loadFlow = (flowId: string) => {
  //   setSelectedFlow(flowId);
  //   FlowManager.loadProject(
  //     flowId,
  //     flows,
  //     blocks,
  //     connections,
  //     setNodes,
  //     setEdges
  //   );
  // };

  const activeFlow = flows.find((flow) => flow.id === selectedFlow);

  return (
    <div className="flex flex-col items-start mb-8 bg-transparent w-full space-y-4">
      {selectedFlow && activeFlow ? (
        <div className="flex flex-row justify-between items-start bg-gray-700 p-4 rounded-lg w-full">
          <div className="flex flex-row space-x-2">
            <h3 className="text-xl font-semibold bg-blue-600 text-white p-2 rounded-lg">
              {activeFlow.name}
            </h3>
          {activeFlow.updated_at && (
            <p className="text-sm text-gray-300 m-auto">
              Last Updated: {new Date(activeFlow.updated_at).toLocaleString()}
            </p>
          )}
          </div>
          <div className="flex flex-row space-x-2">
            <button
              className={`px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed`}
              onClick={() => saveFlow(false)}
              disabled={isSaving}
            >
              {isSaving ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </span>
              ) : (
                'Save Flow'
              )}
            </button>
            <div className="relative group">
              <button
                className={`px-4 py-2 ${usage && usage.action_flows.used >= usage.action_flows.limit ? 'bg-gray-500 hover:bg-gray-600' : 'bg-blue-500'} text-white rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
                onClick={() => usage && usage.action_flows.used >= usage.action_flows.limit ? navigate('/subscription') : saveFlow(true)}
                disabled={isSaving || (usage?.action_flows.used ?? 0) >= (usage?.action_flows.limit ?? 0)}
              >
                {isSaving ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Saving...
                  </span>
                ) : (
                  'Save As New'
                )}
              </button>
              {usage && usage.action_flows.used >= usage.action_flows.limit && (
                <div 
                  className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-50 cursor-pointer"
                  onClick={() => navigate('/subscription')}
                >
                  {limitReachedMessage} Click to upgrade.
                </div>
              )}
            </div>
            <button
              className="px-4 py-2 bg-gray-600 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => {
                setSelectedFlow(null);
                setNodes([]);
                setEdges([]);
              }}
              disabled={isSaving}
            >
              Start New Flow
            </button>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setTabActionEditor(true)}
              disabled={isSaving}
            >
              See All Flows
            </button>
          </div>
        </div>
      ) : (
        <div className="relative group">
          <button
            className={`px-4 py-2 ${usage && usage.action_flows.used >= usage.action_flows.limit ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600'} text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed`}
            onClick={() => saveFlow(true)}
            disabled={isSaving || (usage?.action_flows.used ?? 0) >= (usage?.action_flows.limit ?? 0)}
          >
            {isSaving ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </span>
            ) : (
              'Save New Flow'
            )}
          </button>
          {usage && usage.action_flows.used >= usage.action_flows.limit && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-50">
              {limitReachedMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SimplifiedView;
