import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/store";
import { getVapidKey } from "../utils/getVapidKey";
import { StandardPushSubscription } from "../types/pushSubscriptionTypes";
import { subPushNotifications } from "../api/alertService";
import {
  RiTelegramLine,
  RiWhatsappLine,
  RiMicrosoftFill,
  RiSmartphoneLine,
  RiMailLine,
  RiDiscordLine,
  RiSlackLine,
  RiFacebookCircleLine,
  RiSettings4Line,
  RiChat3Line,
  RiPhoneLine,
  RiWechatLine,
  RiSkypeLine,
  RiLineLine,
  RiLinkedinBoxFill,
  RiTwitterXLine,
  RiInstagramLine,
} from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import {
  updatePreferencesThunk,
  updateNotificationDetailsThunk,
  selectNotificationDetails,
} from "../features/user/userSlice";

const platforms = [
  {
    name: "Email",
    id: "email",
    icon: <RiMailLine className="text-red-500 text-2xl" />,
    placeholder: "Your email address",
    description: "Get email notifications for alerts and updates.",
    available: true,
    limit: 1000,
  },
  {
    name: "Slack",
    id: "slack",
    icon: <RiSlackLine className="text-purple-500 text-2xl" />,
    placeholder: "Slack webhook url",
    description: "Integrate notifications into your Slack channels. How to set up: <a target='_blank' href='https://api.slack.com/messaging/webhooks'>tutorial</a>",
    available: true,
    limit: 1000,
  },
  {
    name: "X",
    id: "twitter",
    icon: <RiTwitterXLine className="text-blue-500 text-2xl" />,
    placeholder: "X handle",
    description: "Receive X DM alerts.",
    available: false,
    limit: 1000,
  },
  {
    name: "Instagram",
    id: "instagram",
    icon: <RiInstagramLine className="text-pink-600 text-2xl" />,
    placeholder: "Instagram username",
    description: "Alerts via Instagram DMs.",
    available: false,
    limit: 1000,
  },
  {
    name: "SMS",
    id: "sms",
    icon: <RiSmartphoneLine className="text-yellow-500 text-2xl" />,
    placeholder: "Mobile number with country code",
    description: "Receive SMS alerts on your phone.",
    available: true,
    limit: 10,
  },
  {
    name: "LinkedIn",
    id: "linkedin",
    icon: <RiLinkedinBoxFill className="text-blue-700 text-2xl" />,
    placeholder: "LinkedIn profile URL",
    description: "Professional alerts through LinkedIn.",
    available: false,
    limit: 1000,
  },
  {
    name: "Teams",
    id: "teams_webhook_url",
    icon: <RiMicrosoftFill className="text-blue-600 text-2xl" />,
    placeholder: "Teams webhook url",
    description: "Connect with your Microsoft Teams workspace.",
    available: false,
    limit: 1000,
  },
  {
    name: "Facebook Messenger",
    id: "facebook_messenger",
    icon: <RiFacebookCircleLine className="text-blue-600 text-2xl" />,
    placeholder: "Messenger ID or linked phone number",
    description: "Connect for instant updates on Facebook Messenger.",
    available: false,
    limit: 1000,
  },
  {
    name: "Telegram",
    id: "telegram",
    icon: <RiTelegramLine className="text-blue-400 text-2xl" />,
    placeholder: "Telegram bot token",
    description: "Get notifications directly in your Telegram chat. How to set up: <a target='_blank' href='https://www.siteguarding.com/en/how-to-get-telegram-bot-api-token'>tutorial</a>",
    available: true,
    limit: 1000,
  },
  {
    name: "Signal",
    id: "signal",
    icon: <RiChat3Line className="text-red-600 text-2xl" />,
    placeholder: "Signal number or identifier",
    description: "Secure messages with Signal notifications.",
    available: false,
    limit: 1000,
  },
  {
    name: "Skype",
    id: "skype",
    icon: <RiSkypeLine className="text-blue-300 text-2xl" />,
    placeholder: "Skype username or email",
    description: "Skype calls or messages for updates.",
    available: false,
    limit: 1000,
  },
  {
    name: "Viber",
    id: "viber",
    icon: <RiPhoneLine className="text-purple-600 text-2xl" />,
    placeholder: "Viber contact number",
    description: "Get Viber messages for alerts.",
    available: false,
    limit: 1000,
  },
  {
    name: "Discord",
    id: "discord_webhook_url",
    icon: <RiDiscordLine className="text-indigo-500 text-2xl" />,
    placeholder: "Discord user ID or channel",
    description: "Join our Discord server for real-time notifications. How to set up: <a target='_blank' href='https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks'>tutorial</a>",
    available: true,
    limit: 1000,
  },
  {
    name: "WhatsApp",
    id: "whatsapp",
    icon: <RiWhatsappLine className="text-green-500 text-2xl" />,
    placeholder: "WhatsApp number with country code",
    description: "Receive alert messages on your WhatsApp.",
    available: false,
    limit: 1000,
  },
  {
    name: "WeChat",
    id: "wechat",
    icon: <RiWechatLine className="text-green-600 text-2xl" />,
    placeholder: "WeChat ID or phone number",
    description: "Receive notifications via WeChat.",
    available: false,
    limit: 1000,
  },
  {
    name: "Line",
    id: "line",
    icon: <RiLineLine className="text-green-700 text-2xl" />,
    placeholder: "Line user ID",
    description: "Connect on Line for instant messages.",
    available: false,
    limit: 1000,
  },
];

const SettingsPage = () => {
  const [activePlatform, setActivePlatform] = useState<string | null>(null);
  const [activePlatformName, setActivePlatformName] = useState<string | null>(null);
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const dispatch = useAppDispatch();
  const notificationDetails = useAppSelector(selectNotificationDetails);
  //
  const [browserNotifications, setBrowserNotifications] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [pushSubscription, setPushSubscription] = useState<StandardPushSubscription | null>(null);

  useEffect(() => {
      async function subscribeUser() {
          if ("serviceWorker" in navigator && "PushManager" in window) {
              const registration = await navigator.serviceWorker.register("/service-worker.js");
              const subscription = await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: getVapidKey()
              });

              const { endpoint, keys } = subscription.toJSON();
              if(endpoint && keys){setPushSubscription({ endpoint, p256dh: keys.p256dh, auth: keys.auth });}
          } else {
              console.warn("Push notifications are not supported by your browser.");
          }
      }

      subscribeUser().catch((error) => {
        console.error('Failed to subscribe to push notifications:', error);
      });
  }, []);

  async function handleSubmitSubscribe() {
      if (pushSubscription) {
          try {
              const response = await subPushNotifications(JSON.stringify(pushSubscription));

              if (response.ok) {
                  console.log("Subscribed successfully!");
              } else {
                  console.error("Failed to subscribe.");
              }
          } catch (err) {
              console.error("Error subscribing:", err);
          }
      }
  }

  // Function to ask for notification permission
  const askNotificationPermission = () => {
    if (!('Notification' in window)) {
        console.log('This browser does not support desktop notifications');
    } else {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            }
        });
    }
  };

  useEffect(()=>{
    console.log(formData);
  }, [formData])

  const handleInputChange = (
    platform: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [platform]: event.target.value });
  };

  const handleUpdateNotificationDetails = () => {
    if (activePlatform) {
      const updatedDetails = {
        ...notificationDetails,
        [activePlatform]: formData[activePlatform],
      };
      // Dispatch the thunk to update notification details
      dispatch(updateNotificationDetailsThunk(updatedDetails));
    }
  };

  const toggleBrowserNotifications = () => {
    if(!browserNotifications){
      askNotificationPermission();
    }
    const updatedDetails = {
      ...notificationDetails,
      ['browser']: !browserNotifications,
    };
    // Dispatch the thunk to update notification details
    dispatch(updateNotificationDetailsThunk(updatedDetails));
    setBrowserNotifications(!browserNotifications);
  };

  const togglePushNotifications = async () => {
    if(!pushNotifications){
      await handleSubmitSubscribe();
    }
    const updatedDetails = {
      ...notificationDetails,
      ['push_notifications']: !pushNotifications,
    };
    // Dispatch the thunk to update notification details
    dispatch(updateNotificationDetailsThunk(updatedDetails));
    setPushNotifications(!pushNotifications);
  };

  const handleChangePasswordClick = () => {
    setShowPasswordModal(true);
  };

  const handlePasswordChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordData({ ...passwordData, [field]: event.target.value });
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleUpdatePassword = () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert("New passwords do not match!");
      return;
    }
    console.log("Password updated to:", passwordData.newPassword);
    setShowPasswordModal(false);
  };

  const handleToggleTheme = () => {
    dispatch(updatePreferencesThunk({ darkMode: !isDarkMode }));
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    if (activePlatform && notificationDetails) {
      setFormData({
        ...formData,
        [activePlatform]: notificationDetails[activePlatform] || "",
      });
    }
  }, [activePlatform, notificationDetails]);

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900 p-6">
      {/* Background elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/50" />

      <div className="relative z-10 max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400">
            Settings
          </h1>
          <motion.div
            whileHover={{ rotate: 180 }}
            transition={{ duration: 0.3 }}
          >
            <RiSettings4Line size={30} className="text-white/80" />
          </motion.div>
        </div>

        {/* Main Content */}
        <div className="backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 p-8 shadow-xl">
          <h2 className="text-2xl font-semibold mb-2 text-white/90">Notification Channels</h2>
          <p className="text-sm text-white/60 mb-6">Configure your default notification preferences</p>

          {/* Platform Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {platforms.map((platform) => (
              <motion.div
                key={platform.id}
                className={`backdrop-blur-xl ${
                  activePlatform === platform.id
                    ? "bg-white/20 border-purple-500/50"
                    : "bg-white/10 border-white/20"
                } rounded-xl border p-4 cursor-pointer transition-all duration-300`}
                onClick={() => {
                  if(platform.available) {
                    setActivePlatform(platform.id);
                    setActivePlatformName(platform.name);
                  }
                }}
                whileHover={platform.available ? { scale: 1.02 } : { scale: 1.0 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center space-x-3">
                    <div className="text-white/80">{platform.icon}</div>
                    <span className="font-medium text-white/90">{platform.name}</span>
                  </div>
                  {!platform.available && (
                    <span className="text-xs px-2 py-1 rounded-full bg-red-500/20 text-red-400">
                      Soon
                    </span>
                  )}
                  {platform.available && (
                    <span className="text-xs px-2 py-1 rounded-full bg-blue-500/20 text-blue-400">
                      {platform.limit}/mo
                    </span>
                  )}
                </div>
              </motion.div>
            ))}
          </div>

          {/* Active Platform Settings */}
          {activePlatformName && activePlatform && (
            <motion.div
              className="mt-8 backdrop-blur-xl bg-white/10 rounded-xl border border-white/20 p-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h3 className="text-xl font-semibold mb-3 text-white/90">
                {activePlatformName} Configuration
              </h3>
              <p className="text-sm text-white/60 mb-4"
                dangerouslySetInnerHTML={{
                  __html: platforms.find((p) => p.name === activePlatformName)?.description || ''
                }}
              />
              <motion.input
                whileFocus={{ scale: 1.01 }}
                type="text"
                placeholder={platforms.find((p) => p.name === activePlatformName)?.placeholder}
                value={formData[activePlatform] || ""}
                onChange={(e) => handleInputChange(activePlatform, e)}
                className="w-full p-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/40 focus:border-purple-500 focus:outline-none transition-all duration-300"
              />
              <div className="flex justify-end mt-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleUpdateNotificationDetails}
                  className="px-6 py-2.5 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
                >
                  Update
                </motion.button>
              </div>
            </motion.div>
          )}

          {/* Notification Toggles */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            {/* Browser Notifications */}
            <div className="backdrop-blur-xl bg-white/10 rounded-xl border border-white/20 p-6">
              <h2 className="text-xl font-semibold mb-4 text-white/90">Browser Notifications</h2>
              <ToggleSwitch
                checked={browserNotifications}
                onChange={toggleBrowserNotifications}
                label={browserNotifications ? "Enabled" : "Disabled"}
              />
            </div>

            {/* Push Notifications */}
            <div className="backdrop-blur-xl bg-white/10 rounded-xl border border-white/20 p-6">
              <h2 className="text-xl font-semibold mb-4 text-white/90">Push Notifications</h2>
              <ToggleSwitch
                checked={pushNotifications}
                onChange={togglePushNotifications}
                label={pushNotifications ? "Enabled" : "Disabled"}
              />
            </div>
          </div>

          {/*Password */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        
        


            {/* Password Change */}
            <div className="backdrop-blur-xl bg-white/10 rounded-xl border border-white/20 p-6">
              <h2 className="text-xl font-semibold mb-4 text-white/90">Security</h2>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleChangePasswordClick}
                className="px-6 py-2.5 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
              >
                Change Password
              </motion.button>
            </div>
          </div>
        </div>
      </div>

      {/* Password Modal */}
      <AnimatePresence>
        {showPasswordModal && (
          <motion.div
            className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 p-8 w-full max-w-md"
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
            >
              <h3 className="text-2xl font-semibold mb-6 text-white/90">Change Password</h3>
              {/* Password inputs */}
              <div className="space-y-4">
                <PasswordInput
                  label="Current Password"
                  value={passwordData.oldPassword}
                  onChange={(e) => handlePasswordChange("oldPassword", e)}
                />
                <PasswordInput
                  label="New Password"
                  value={passwordData.newPassword}
                  onChange={(e) => handlePasswordChange("newPassword", e)}
                />
                <PasswordInput
                  label="Confirm New Password"
                  value={passwordData.confirmPassword}
                  onChange={(e) => handlePasswordChange("confirmPassword", e)}
                />
              </div>
              
              <div className="flex justify-end gap-4 mt-8">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setShowPasswordModal(false)}
                  className="px-6 py-2.5 rounded-xl bg-white/10 border border-white/20 text-white/80 font-medium hover:bg-white/20 transition-all duration-300"
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleUpdatePassword}
                  className="px-6 py-2.5 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
                >
                  Update Password
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Komponenty pomocnicze
interface ToggleSwitchProps {
  checked: boolean;
  onChange: () => void;
  label: string;
}

const ToggleSwitch = ({ checked, onChange, label }: ToggleSwitchProps) => (
  <label className="flex items-center cursor-pointer">
    <div className="relative">
      <input
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={onChange}
      />
      <div className={`w-14 h-8 rounded-full flex items-center transition-all duration-300 ${
        checked ? "bg-gradient-to-r from-purple-500 to-pink-500" : "bg-white/10"
      }`}>
        <div className={`w-6 h-6 rounded-full bg-white transition-all duration-300 ${
          checked ? "translate-x-8" : "translate-x-1"
        }`} />
      </div>
    </div>
    <span className="ml-3 text-sm font-medium text-white/80">{label}</span>
  </label>
);

const PasswordInput = ({ label, value, onChange }: {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <div>
    <label className="block text-sm font-medium text-white/80 mb-2">{label}</label>
    <input
      type="password"
      value={value}
      onChange={onChange}
      className="w-full p-3 rounded-xl bg-white/10 border border-white/20 text-white placeholder-white/40 focus:border-purple-500 focus:outline-none transition-all duration-300"
    />
  </div>
);

export default SettingsPage;
