// src/components/TimeTriggerNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { selectUsage } from '../../../features/user/subscriptionSlice';
import "react-datepicker/dist/react-datepicker.css";

const TimeTriggerNode = ({
  id,
  data,
  onDelete,
  onUpdate,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
  onUpdate: (id: string, newData: any) => void;
}) => {
  const [triggerType, setTriggerType] = useState(data["trigger_type"] || "specific_dates");
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [specificDates, setSpecificDates] = useState<Date[]>(data["specific_dates"] || []);
  const [timesOfDay, setTimesOfDay] = useState<string[]>(data["times_of_day"] || []);
  const [intervalValue, setIntervalValue] = useState(data["delay"] || "");
  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const usage = useSelector(selectUsage);

  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  const updateNodeData = (newData: any) => {
    onUpdate(id, { ...data, ...newData });
  };

  useEffect(() => {
    const data = {
      "trigger_type":triggerType,
      "specific_dates":specificDates,
      "times_of_day":timesOfDay,
      "delay":intervalValue,
    };
    console.log(data);
    updateNodeData(data);
  }, [triggerType, specificDates, timesOfDay, intervalValue]);

  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleTriggerTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTriggerType(event.target.value);
  };

  const handleAddDateTime = () => {
    if (selectedDate) {
      setSpecificDates([...specificDates, selectedDate]);
      setSelectedDate(null);
    }
  };

  const handleAddTime = () => {
    if (selectedTime) {
      setTimesOfDay([...timesOfDay, selectedTime]);
      setSelectedTime("");
    }
  };

  const handleDeleteDate = (index: number) => {
    const newDates = [...specificDates];
    newDates.splice(index, 1);
    setSpecificDates(newDates);
  };

  const handleDeleteHour = (index: number) => {
    const newHours = [...timesOfDay];
    newHours.splice(index, 1);
    setTimesOfDay(newHours);
  };

  const handleIntervalValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntervalValue(event.target.value);
  };

  const getMinimumDelay = () => {
    return usage?.limits?.trigger_delay_minutes || 30;
  };

  const renderValidationMessage = () => {
    if (!triggerType) return "Schedule type is required";
    
    switch (triggerType) {
      case "specific_dates":
        return specificDates.length === 0 ? "At least one date is required" : "";
      case "times_of_day":
        return timesOfDay.length === 0 ? "At least one time is required" : "";
      case "delay":
        if (!intervalValue) return "Interval is required";
        if (Number(intervalValue) < getMinimumDelay()) {
          return `Upgrade required: Minimum interval\n is ${getMinimumDelay()} minutes on your plan`;
        }
        return "";
    }
  };

  const renderTriggerOptions = () => {
    switch (triggerType) {
      case "specific_dates":
        return (
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <DatePicker
                selected={selectedDate}
                onChange={setSelectedDate}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className={`${darkBlueInputStyle} flex-grow`}
                placeholderText="Select date and time"
                isClearable
              />
              <button
                onClick={handleAddDateTime}
                disabled={!selectedDate}
                className={`px-3 py-1 rounded ${
                  selectedDate ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-500'
                }`}
              >
                Add
              </button>
            </div>
            <ul className="space-y-1">
              {specificDates.map((date, index) => (
                <li key={index} className="flex items-center justify-between bg-gray-700 p-2 rounded">
                  <span>{date.toLocaleString()}</span>
                  <button
                    className="text-red-400 hover:text-red-300"
                    onClick={() => handleDeleteDate(index)}
                  >
                    ×
                  </button>
                </li>
              ))}
            </ul>
          </div>
        );

      case "times_of_day":
        return (
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className={`${darkBlueInputStyle} flex-grow`}
              />
              <button
                onClick={handleAddTime}
                disabled={!selectedTime}
                className={`px-3 py-1 rounded ${
                  selectedTime ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-500'
                }`}
              >
                Add
              </button>
            </div>
            <ul className="space-y-1">
              {timesOfDay.map((time, index) => (
                <li key={index} className="flex items-center justify-between bg-gray-700 p-2 rounded">
                  <span>{time}</span>
                  <button
                    className="text-red-400 hover:text-red-300"
                    onClick={() => handleDeleteHour(index)}
                  >
                    ×
                  </button>
                </li>
              ))}
            </ul>
          </div>
        );

      case "delay":
        return (
          <div className="space-y-2">
            <input
              type="number"
              value={intervalValue}
              onChange={handleIntervalValueChange}
              min={getMinimumDelay()}
              className={`${darkBlueInputStyle} w-full`}
              placeholder={`Minimum ${getMinimumDelay()} minutes`}
            />
            {Number(intervalValue) < getMinimumDelay() && (
              <div className="text-yellow-400 text-sm">
                Upgrade required: Minimum interval is {getMinimumDelay()} minutes on your plan
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <NodeContainer
      id={id}
      className="bg-[#2E9E71]"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold">Time Trigger</h3>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Schedule:</label>
          <select
            className={`${darkBlueInputStyle} w-full`}
            value={triggerType}
            onChange={handleTriggerTypeChange}
          >
            <option value="specific_dates">Specific Dates</option>
            <option value="times_of_day">Daily</option>
            <option value="delay">Every x Minutes</option>
          </select>
        </div>

        {renderTriggerOptions()}

        {renderValidationMessage() && (
          <div className="text-red-400 text-sm">
            {renderValidationMessage()}
          </div>
        )}
      </div>

      <NodeHandle
        id="signal-out"
        type="source"
        position={Position.Right}
        label="Output"
        style={{ top: "90%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() =>
          handleHoverText(
            "This output sends the trigger signal to the connected node(s) based on the selected schedule."
          )
        }
        onLeave={handleMouseLeave}
      />
    </NodeContainer>
  );
};

export default TimeTriggerNode;