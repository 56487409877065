// src/components/EmailCheckNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import NewEmailModal from "../modals/NewEmailModal";
import { getEmailCredentials } from "../../../api/flowsService";
import { EmailCredential } from "../../../types/flowTypes";

const EmailCheckNode = ({
  id,
  data,
  onDelete,
  onUpdate,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
  onUpdate: (id: string, newData: any) => void;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [keywords, setKeywords] = useState<string[]>(data["keywords"] || []);
  const [newKeyword, setNewKeyword] = useState("");
  const [selectedEmail, setSelectedEmail] = useState<string>(data["email"] || "");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();

  const [emailCredentials, setEmailCredentials] = useState<EmailCredential[]>([]);
  const [selectedCredential, setSelectedCredential] = useState<EmailCredential | null>(null);

  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  useEffect(() => {
    loadEmailCredentials();
  }, []);

  const loadEmailCredentials = async () => {
    try {
      const credentials = await getEmailCredentials();
      setEmailCredentials(credentials);
      
      // If there's a saved credential ID, select it
      if (data.credentialId && credentials.length > 0) {
        const savedCredential = credentials.find(c => c.id === data.credentialId);
        if (savedCredential) {
          setSelectedCredential(savedCredential);
        }
      }
    } catch (error) {
      console.error("Failed to load email credentials:", error);
    }
  };

  const updateNodeData = (newData: any) => {
    onUpdate(id, { ...data, ...newData });
  };

  useEffect(() => {
    const data = {
      "email":selectedEmail,
      "keywords":keywords,
    };
    console.log(data);
    updateNodeData(data);
  }, [selectedEmail, keywords]);

  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      setKeywords([...keywords, newKeyword.trim()]);
      setNewKeyword("");
    }
  };

  const handleDeleteKeyword = (index: number) => {
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
  };

  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleAddEmailAccount = async (credential: EmailCredential) => {
    try {
      // First update the credentials list
      await loadEmailCredentials();
      
      // Then set the selected credential
      setSelectedCredential(credential);
      setModalVisible(false);
      
      // Update node data
      updateNodeData({
        credentialId: credential.id,
        email: credential.email_address
      });
    } catch (error) {
      console.error("Error adding email account:", error);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleEditCredential = (credential: EmailCredential) => {
    setSelectedCredential(credential); // Set the credential being edited
    setModalVisible(true);
    setDropdownVisible(false);
  };

  const renderEmailDropdown = () => (
    <div className="relative">
      <div
        className={`${darkBlueInputStyle} w-full cursor-pointer flex justify-between items-center`}
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <span>{selectedCredential?.email_address || "Select an Email"}</span>
        <span className="ml-2">&#9660;</span>
      </div>
      {dropdownVisible && (
        <ul className="absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10">
          {emailCredentials.map((credential) => (
            <li key={credential.id} className="p-2 hover:bg-gray-700">
              <div className="flex justify-between items-center">
                <span className="cursor-pointer"
                  onClick={() => {
                    setSelectedCredential(credential);
                    setDropdownVisible(false);
                    updateNodeData({
                      credentialId: credential.id,
                      email: credential.email_address
                    });
                  }}>
                  {credential.email_address}
                </span>
                <button
                  className="ml-2 px-2 py-1 bg-blue-500 rounded hover:bg-blue-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedCredential(credential);
                    setModalVisible(true);
                    setDropdownVisible(false);
                  }}
                >
                  Edit
                </button>
              </div>
            </li>
          ))}
          <li
            className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
            onClick={() => {
              setSelectedCredential(null);
              setModalVisible(true);
              setDropdownVisible(false);
            }}
          >
            Add Email
          </li>
        </ul>
      )}
    </div>
  );

  return (
    <NodeContainer
      id={id}
      className="bg-[#355CA8]"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold">Check Email</h3>
      <label>Choose Email Account:</label>
      {renderEmailDropdown()}
      <label className="block mt-2">Keyword/Regex:</label>
      <div className="flex items-center">
        <input
          type="text"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          placeholder="Keyword/Regex"
          className={darkBlueInputStyle}
        />
        <button
          className="ml-2 p-2 bg-green-700 text-white rounded-md"
          onClick={handleAddKeyword}
        >
          Add
        </button>
      </div>
      <ul className="mt-2">
      {keywords.map((keyword, index) => (
          <li key={index} className="flex items-center justify-between">
            {keyword}
            <button
              className="mx-2 p-1 bg-red-500 text-white rounded-md text-sm"
              onClick={() => handleDeleteKeyword(index)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>

      <NodeHandle
        id="signal-in"
        type="target"
        position={Position.Left}
        label="Input"
        style={{ top: "50%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Input email stream to be filtered.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="signal-out"
        type="source"
        position={Position.Right}
        label="Received"
        style={{ top: "16%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Emails containing the specified keyword/regex.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="error-out"
        type="source"
        position={Position.Right}
        label="No Mail"
        style={{ top: "80%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("No new emails detected matching the specified criteria.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="email-out"
        type="source"
        position={Position.Right}
        label="Email"
        style={{ top: "32%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Sender of the detected email.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="text-out"
        type="source"
        position={Position.Right}
        label="Content"
        style={{ top: "48%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Content of the detected email.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="file-out"
        type="source"
        position={Position.Right}
        label="Files"
        style={{ top: "64%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Attachments of the detected email.")}
        onLeave={handleMouseLeave}
      />

      {modalVisible && (
        <NewEmailModal
          onClose={() => setModalVisible(false)}
          onAddEmail={handleAddEmailAccount}
          editCredential={selectedCredential || undefined}
        />
      )}
    </NodeContainer>
  );
};

export default EmailCheckNode;