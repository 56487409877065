// src/components/NodeContainer.tsx
import React, { useState, ReactNode, CSSProperties } from "react";
import { useReactFlow } from "react-flow-renderer";
import DeleteNodeButton from "./DeleteButton";
import "./CustomNodes.css";

const tooltipStyle: CSSProperties = {
  position: "absolute",
  zIndex: 10,
  border: "1px solid #ddd",
  padding: "8px",
  borderRadius: "4px",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  width: "200px",
  fontSize: "12px",
};

const darkBlueInputStyle = "mt-2 p-2 bg-gray-800 text-white rounded-md";

const NodeContainer = ({
  id,
  children,
  className,
  onDelete,
  showTooltip,
  tooltipText,
}: {
  id: string;
  children: ReactNode;
  className: string;
  onDelete: (id: string) => void;
  showTooltip: boolean;
  tooltipText: string;
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const zoom = useReactFlow().getZoom();

  const handleMouseMove = (event: React.MouseEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const offsetX = (event.clientX - rect.left) / zoom;
    const offsetY = (event.clientY - rect.top) / zoom;
    setMousePosition({ x: offsetX, y: offsetY });
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      className={`p-4 pr-20 rounded-lg shadow-lg relative ${className}`}
    >
      <DeleteNodeButton id={id} onDelete={onDelete} />
      {children}
      {showTooltip && (
        <div
          className="bg-gray-800"
          style={{
            ...tooltipStyle,
            top: mousePosition.y + 10,
            left: mousePosition.x + 10,
          }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export { NodeContainer, darkBlueInputStyle };
