// src/components/PublishYouTubeShortsNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import NewYTAccountModal from "../modals/NewYTAccountModal";
import { FaQuestionCircle } from 'react-icons/fa';
import { getYouTubeCredentials, createYouTubeCredential } from '../../../api/flowsService';
import { YouTubeCredential } from '../../../types/flowTypes';

const PublishYouTubeShortsNode = ({
  id,
  data,
  onDelete,
  onUpdate,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
  onUpdate: (id: string, newData: any) => void;
}) => {
  // State management
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [title, setTitle] = useState(data.title || "");
  const [description, setDescription] = useState(data.description || "");
  const [file, setFile] = useState<File | null>(data.file || null);
  const [selectedAccount, setSelectedAccount] = useState<YouTubeCredential | null>(null);
  const [accounts, setAccounts] = useState<YouTubeCredential[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [audience, setAudience] = useState(data.audience || "General Audience");
  const [category, setCategory] = useState(data.category || "Entertainment");
  const [visibility, setVisibility] = useState(data.visibility || "Public");

  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();

  // Load YouTube accounts on mount
  useEffect(() => {
    loadYouTubeAccounts();
  }, []);

  // Update handle styles when data changes
  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  // Load saved account if exists
  const loadYouTubeAccounts = async () => {
    try {
      const credentials = await getYouTubeCredentials();
      setAccounts(credentials);
      
      if (data.youtubeAccountId) {
        const savedAccount = credentials.find(c => c.id === data.youtubeAccountId);
        if (savedAccount) {
          setSelectedAccount(savedAccount);
        }
      }
    } catch (error) {
      console.error("Failed to load YouTube accounts:", error);
    }
  };

  // Update node data when relevant state changes
  const updateNodeData = (newData: any) => {
    onUpdate(id, { ...data, ...newData });
  };

  useEffect(() => {
    updateNodeData({
      youtubeAccountId: selectedAccount?.id,
      channel_name: selectedAccount?.channel_name,
      title,
      description,
      file,
      audience,
      category,
      visibility,
    });
  }, [selectedAccount, title, description, file, audience, category, visibility]);

  // Event handlers
  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const renderAccountDropdown = () => (
    <div className="relative">
      <div
        className={`${darkBlueInputStyle} w-full cursor-pointer flex justify-between items-center`}
        onClick={handleDropdownToggle}
      >
        <span>{selectedAccount?.channel_name || "Select YouTube Channel"}</span>
        <span className="ml-2">&#9660;</span>
      </div>
      
      {dropdownVisible && (
        <ul className="absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10">
          {accounts.map((account) => (
            <li
              key={account.id}
              className="p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => {
                setSelectedAccount(account);
                setDropdownVisible(false);
                updateNodeData({
                  youtubeAccountId: account.id,
                  channel_name: account.channel_name
                });
              }}
            >
              {account.channel_name}
            </li>
          ))}
          <li
            className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
            onClick={() => {
              setModalVisible(true);
              setDropdownVisible(false);
            }}
          >
            Add Channel
          </li>
        </ul>
      )}
    </div>
  );

  return (
    <NodeContainer
      id={id}
      className="bg-[#A11A1A]"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold flex items-center justify-between">
        Publish YouTube Shorts
      </h3>

      <label>YouTube Channel:</label>
      {renderAccountDropdown()}

      <label className="block mt-2">Title:</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        className={darkBlueInputStyle}
      />

      <label className="block mt-2">Description:</label>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        className={darkBlueInputStyle}
      />

      <label className="block mt-2">Video (up to 60s):</label>
      <input
        type="file"
        accept="video/*"
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length > 0) {
            setFile(files[0]);
          }
        }}
        className={darkBlueInputStyle}
      />

      <label className="block mt-2">Audience:</label>
      <select
        value={audience}
        onChange={(e) => setAudience(e.target.value)}
        className={darkBlueInputStyle}
      >
        <option value="General Audience">General Audience</option>
        <option value="Kids">Kids</option>
        <option value="Adults">Adults</option>
      </select>

      <label className="block mt-2">Category:</label>
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        className={darkBlueInputStyle}
      >
        <option value="Entertainment">Entertainment</option>
        <option value="Education">Education</option>
        <option value="Gaming">Gaming</option>
        <option value="Music">Music</option>
        <option value="News">News</option>
        <option value="Sports">Sports</option>
        <option value="Travel">Travel</option>
      </select>

      <label className="block mt-2">Visibility:</label>
      <select
        value={visibility}
        onChange={(e) => setVisibility(e.target.value)}
        className={darkBlueInputStyle}
      >
        <option value="Public">Public</option>
        <option value="Unlisted">Unlisted</option>
        <option value="Private">Private</option>
      </select>
      <NodeHandle
        id="signal-in"
        type="target"
        position={Position.Left}
        label="Send Signal"
        style={{ top: "20%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Start publishing signal from the previous block.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="file-in"
        type="target"
        position={Position.Left}
        label="Video"
        style={{ top: "40%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: video file fitting YouTube Shorts restrictions.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="text-in"
        type="target"
        position={Position.Left}
        label="Title"
        style={{ top: "60%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: video title.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="text-in"
        type="target"
        position={Position.Left}
        label="Description"
        style={{ top: "80%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: video description.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="signal-out"
        type="source"
        position={Position.Right}
        label="Published"
        style={{ top: "50%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Video published successfully.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="error-out"
        type="source"
        position={Position.Right}
        label="Error"
        style={{ top: "80%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Publishing video failed.")}
        onLeave={handleMouseLeave}
      />
      {modalVisible && (
        <NewYTAccountModal
          onClose={handleModalClose}
          onSuccess={() => {
            loadYouTubeAccounts();  // Refresh the accounts list
            handleModalClose();
          }}
        />
      )}
    </NodeContainer>
  );
};

export default PublishYouTubeShortsNode;