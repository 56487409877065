// src/components/ActionCreatorTab.tsx
import React, { useEffect, useRef, useState, useMemo, SetStateAction, Dispatch } from "react";
import { motion } from "framer-motion";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Edge,
  Node,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
  NodeChange,
  EdgeChange,
} from "react-flow-renderer";
import {
  FaRobot,
  FaYoutube,
  FaEnvelope,
  FaClock,
  FaTwitter,
} from "react-icons/fa";
import { useAppDispatch, useAppSelector, RootState } from "../../../app/store";
import {
  fetchFlows,
  addFlow,
  modifyFlow,
} from "../../../features/flows/flowsSlice";
import {
  fetchBlocksByFlow,
  addBlock,
  modifyBlock,
  removeBlock,
} from "../../../features/flows/blocksSlice";
import {
  fetchConnectionsByFlow,
  addConnection,
  removeConnection,
} from "../../../features/flows/connectionsSlice";
import { nodeTypes } from "../nodes/CustomNodes";
import { isConnectionValid } from "../utils/connectionValidation";
import CustomEdge, { CustomEdgeProps } from "../nodes/CustomEdge";
import TimeTriggerNode from "../nodes/TimeTriggerNode";
import EmailCheckNode from "../nodes/EmailCheckNode";
import SendEmailNode from "../nodes/SendEmailNode";
import PublishYTShortsNode from "../nodes/PublishYTShortsNode";
import PublishXNode from "../nodes/PublishXNode";
import GenerateVideoNode from "../nodes/VideoGeneratorNode";
import {
  triggerSuccessAnimation,
  triggerRejectionAnimation,
  resetHandleColor,
  captureThumbnail,
} from "../utils/flowTabUtils";
import ProjectManager from "../projectManager/ProjectManager";
import { Tab } from "../../../pages/ActionsPage";

const draggableElements = [
  {
    name: "Time Trigger",
    icon: <FaClock className="text-[#2E9E71]" />,
    options: ["Time Trigger"],
  },
  {
    name: "Email",
    icon: <FaEnvelope className="text-[#355CA8]" />,
    options: ["Check Email", "Send Email"],
  },
  {
    name: "AI Video Generator",
    icon: <FaRobot className="text-[#1A9B97]" />,
    options: ["Generate Video"],
  },
  {
    name: "YouTube",
    icon: <FaYoutube className="text-[#A11A1A]" />,
    options: ["Publish YT Shorts"],
  },
  {
    name: "X (Twitter)",
    icon: <FaTwitter className="text-[#763FB2]" />,
    options: ["Publish on X"],
  },
];

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

const ActionCreatorTab = ({ tab, setTab }: { tab: string, setTab: Dispatch<SetStateAction<Tab>> }) => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);
  const dispatch = useAppDispatch();
  const blocks = useAppSelector((state: RootState) => state.blocks.items);
  const connections = useAppSelector(
    (state: RootState) => state.connections.items
  );

  // useEffect(()=>{
  //   console.log(`Nodes and Edges states:`);
  //   console.log(nodes);
  //   console.log(edges);
  // },[nodes, edges])

  const setTabActionEditor=(reverse=false)=>{
    if(!reverse){
      setTab('new-action');
    }else{
      setTab('action-list');
    }
  };

  const handleNodeUpdate = (id: string, newData: any) => {
    setNodes((nds) =>
      nds.map((node) => (node.id === id ? { ...node, data: newData } : node))
    );
  };

  const onNodesChange = (changes: NodeChange[]) =>
    setNodes((nds) => applyNodeChanges(changes, nds));
  const onEdgesChange = (changes: EdgeChange[]) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));

  const onConnect = (params: Connection) => {
    const { source, sourceHandle = "", target, targetHandle = "" } = params;

    if (source && sourceHandle && target && targetHandle) {
      const { valid, message } = isConnectionValid(
        source,
        sourceHandle,
        target,
        targetHandle
      );

      if (valid) {
        setEdges((eds) =>
          addEdge(
            {
              ...params,
              type: "custom",
              data: {
                sourceNodeID: source,
                sourceHandleID: sourceHandle || "",
                targetNodeID: target,
                targetHandleID: targetHandle || "",
              },
            },
            eds
          )
        );
        triggerSuccessAnimation(setNodes, source, sourceHandle);
        triggerSuccessAnimation(setNodes, target, targetHandle);
      } else {
        triggerRejectionAnimation(setNodes, source, sourceHandle, message);
        triggerRejectionAnimation(setNodes, target, targetHandle, message);
      }
    }
  };

  const onEdgeClick: CustomEdgeProps["onEdgeClick"] = (
    event,
    edgeId,
    sourceNodeID,
    sourceHandleID,
    targetNodeID,
    targetHandleID
  ) => {
    event.stopPropagation(); // Prevent global deselect
    // dispatch(removeConnection(edgeId));
    // console.warn(`Removing edge: ${edgeId}`);
    // console.warn(`Source Node ID: ${sourceNodeID}, Source Handle ID: ${sourceHandleID}`);
    // console.warn(`Target Node ID: ${targetNodeID}, Target Handle ID: ${targetHandleID}`);
    setEdges((eds) => eds.filter((edge) => edge.id !== edgeId));
    resetHandleColor(setNodes, sourceNodeID, sourceHandleID);
    resetHandleColor(setNodes, targetNodeID, targetHandleID);
  };

  // Custom Edge Registration
  const edgeTypes = useMemo(
    () => ({
      custom: (props: any) => (
        <CustomEdge {...props} onEdgeClick={onEdgeClick} />
      ),
    }),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      "Time Trigger": (props: any) => (
        <TimeTriggerNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Check Email": (props: any) => (
        <EmailCheckNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Send Email": (props: any) => (
        <SendEmailNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Generate Video": (props: any) => (
        <GenerateVideoNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Publish YT Shorts": (props: any) => (
        <PublishYTShortsNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Publish on X": (props: any) => (
        <PublishXNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
    }),
    []
  );

  const handleDrop = (
    event: React.DragEvent,
    option: string
  ) => {
    if (reactFlowWrapper.current) {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const newNode = {
        id: `${option}-${nodes.length + 1}`,
        data: {
          id: `${option}-${nodes.length + 1}`,
        },
        position,
        type: option,
      };
      setNodes((nds) => nds.concat(newNode));
    }
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const removeNode = (id: string) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== id && edge.target !== id)
    );
    // dispatch(removeBlock(id));
  };

  useEffect(() => {
    dispatch(fetchFlows());
    dispatch(fetchBlocksByFlow("1")); // Replace with the actual flow ID
    dispatch(fetchConnectionsByFlow("1")); // Replace with the actual flow ID
  }, [dispatch]);

  return (
    <main className="w-full mx-[2%] flex-grow">
      {tab === "new-action" && (
        <div>
          <motion.div
            className="text-center mb-2"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-default-gradient mt-20">
              Create Your Action Flow
            </h2>
            <p className="text-lg mt-1">
              Drag and drop elements to build your custom automation flow.
            </p>
          </motion.div>

          <section className="grid md:grid-cols-2 lg:grid-cols-5 gap-6 mb-2">
            {draggableElements.map((element, index) => (
              <motion.div
                key={index}
                className="p-3 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center platform-card"
                initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <div className="text-4xl mb-2">{element.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{element.name}</h3>
                <div className="flex items-start space-x-2">
                  {element.options.map((option, i) => (
                    <div
                      key={i}
                      className="draggable-item"
                      draggable
                      onDragStart={(event) => {
                        event.dataTransfer.setData("option", option);
                      }}
                    >
                      {element.icon} <p className="ml-2">{option}</p>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </section>

          <div
            className="drop-zone bg-gray-700 p-4 rounded-lg mb-4"
            onDragOver={onDragOver}
            onDrop={(event) => {
              const option = event.dataTransfer.getData("option");
              handleDrop(event, option);
            }}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              edgeTypes={edgeTypes}
              fitView
              onInit={(instance) => (reactFlowInstance.current = instance)}
              nodeTypes={nodeTypes}
            >
              <Background />
            </ReactFlow>
          </div>
        </div>
      )}
      <ProjectManager
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
        setEdges={setEdges}
        captureThumbnail={captureThumbnail}
        reactFlowInstance={reactFlowInstance}
        simpleView={tab === "new-action"}
        setTabActionEditor={setTabActionEditor}
      />
    </main>
  );
};

export default ActionCreatorTab;
