import React from "react";
import { EmailCredential } from "../../../types/flowTypes";

interface EmailCredentialDropdownProps {
  credentials: EmailCredential[];
  selectedCredential: EmailCredential | null;
  onSelect: (credential: EmailCredential) => void;
  onAddNew: () => void;
  className?: string;
}

export const EmailCredentialDropdown: React.FC<EmailCredentialDropdownProps> = ({
  credentials,
  selectedCredential,
  onSelect,
  onAddNew,
  className = ""
}) => {
  return (
    <ul className={`absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10 ${className}`}>
      {credentials.map((credential) => (
        <li
          key={credential.id}
          className="p-2 hover:bg-gray-700 cursor-pointer flex justify-between items-center"
          onClick={() => onSelect(credential)}
        >
          <span>{credential.email_address}</span>
          <span className="text-sm text-gray-400">({credential.provider_type})</span>
        </li>
      ))}
      <li
        className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
        onClick={onAddNew}
      >
        Add Email Account
      </li>
    </ul>
  );
};

interface RecipientListProps {
  recipients: string[];
  onDelete: (index: number) => void;
}

export const RecipientList: React.FC<RecipientListProps> = ({ recipients, onDelete }) => {
  return (
    <ul className="mt-2 space-y-2">
      {recipients.map((recipient, index) => (
        <li key={index} className="flex items-center justify-between bg-gray-700 p-2 rounded">
          <span className="truncate">{recipient}</span>
          <button
            className="ml-2 p-1 bg-red-500 hover:bg-red-600 text-white rounded-md text-sm"
            onClick={() => onDelete(index)}
          >
            Remove
          </button>
        </li>
      ))}
    </ul>
  );
}; 