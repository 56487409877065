import { Block, Flow } from '../../../types/flowTypes';

export interface TimeBlockData {
  trigger_type: 'specific_dates' | 'times_of_day' | 'delay' | 'cron_expression';
  specific_dates?: string[];
  times_of_day?: string[];
  delay?: number | string;
  cron_expression?: string;
  id?: string;
  errorMessage?: string;
  handleColors?: Record<string, string>;
}

// Cache for parsed time block data
const timeBlockParseCache = new Map<string, TimeBlockData | null>();

export const parseTimeBlockData = (block: Block): TimeBlockData | null => {
  // Use block ID as cache key
  const cacheKey = `${block.id}-${block.updated_at}`;
  if (timeBlockParseCache.has(cacheKey)) {
    return timeBlockParseCache.get(cacheKey)!;
  }

  if (block.block_type !== 'time' || !block.data) {
    timeBlockParseCache.set(cacheKey, null);
    return null;
  }

  const data = block.data as TimeBlockData;
  
  if (!data.trigger_type) {
    timeBlockParseCache.set(cacheKey, null);
    return null;
  }

  // Cache the result
  timeBlockParseCache.set(cacheKey, data);
  return data;
};

// Cache for date strings to avoid repeated calculations
const dateStringCache = new Map<number, string>();

const getDateString = (date: Date): string => {
  const timestamp = date.getTime();
  if (dateStringCache.has(timestamp)) {
    return dateStringCache.get(timestamp)!;
  }
  const dateStr = date.toISOString().split('T')[0];
  dateStringCache.set(timestamp, dateStr);
  return dateStr;
};

interface FlowStatus {
  hasErrors: boolean;
  hasSuccess: boolean;
  isLatestSuccessful: boolean;
  latestRun?: {
    status: 'completed' | 'failed';
    timestamp: string;
  };
}

// Cache for flow status to avoid repeated calculations
const flowStatusCache = new Map<string, Map<string, FlowStatus>>();

export const getFlowStatusForDate = (flow: Flow, date: Date): FlowStatus => {
  const dateKey = getDateString(date);
  const flowCache = flowStatusCache.get(flow.id!) ?? new Map();
  
  if (flowCache.has(dateKey)) {
    return flowCache.get(dateKey)!;
  }

  const targetDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  
  // Get all runs for the date
  const runsForDate = flow.success_log?.filter(run => {
    try {
      const runDate = new Date(run.start_time);
      return runDate.getFullYear() === targetDate.getFullYear() &&
             runDate.getMonth() === targetDate.getMonth() &&
             runDate.getDate() === targetDate.getDate() &&
             // Only count runs with non-time blocks
             run.blocks.some((block: Block) => block.block_type !== 'time');
    } catch {
      return false;
    }
  }) ?? [];

  // Get latest run
  const latestRun = runsForDate[runsForDate.length - 1];
  const isLatestSuccessful = latestRun?.status === 'completed';

  // Check for errors in error_log
  const hasErrorsInLog = flow.error_log?.some(log => {
    try {
      const errorData = typeof log === 'string' ? JSON.parse(log) : log;
      const logDate = new Date(errorData.timestamp);
      return logDate.getFullYear() === targetDate.getFullYear() &&
             logDate.getMonth() === targetDate.getMonth() &&
             logDate.getDate() === targetDate.getDate();
    } catch {
      return false;
    }
  }) ?? false;

  // Check for failed runs in success_log
  const hasFailedRuns = runsForDate.some(run => run.status === 'failed');

  const result: FlowStatus = {
    hasErrors: hasErrorsInLog || hasFailedRuns,
    hasSuccess: runsForDate.length > 0,
    isLatestSuccessful,
    latestRun: latestRun ? {
      status: latestRun.status,
      timestamp: latestRun.start_time
    } : undefined
  };
  
  flowCache.set(dateKey, result);
  flowStatusCache.set(flow.id!, flowCache);
  
  return result;
};

// Helper function to check if a date is in the past
const isDateInPast = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const compareDate = new Date(date);
  compareDate.setHours(0, 0, 0, 0);
  return compareDate < today;
};

export const isDateInRange = (date: Date, timeBlockData: TimeBlockData, flow: Flow): boolean => {
  // For past dates, only show if there are logs
  if (isDateInPast(date)) {
    const { hasErrors, hasSuccess } = getFlowStatusForDate(flow, date);
    return hasErrors || hasSuccess;
  }
  switch (timeBlockData.trigger_type) {
    case 'times_of_day':
      return (timeBlockData.times_of_day?.length ?? 0) > 0;
      
    case 'delay':
      return true;
      
    case 'specific_dates':
      return timeBlockData.specific_dates?.includes(getDateString(date)) ?? false;
      
    case 'cron_expression':
      return !!timeBlockData.cron_expression;
      
    default:
      return false;
  }
};

// Cache for execution times
const executionTimesCache = new Map<string, string[]>();

export const getExecutionTimesForDate = (date: Date, timeBlockData: TimeBlockData, flow: Flow): string[] => {
  const cacheKey = `${flow.id}-${getDateString(date)}-${timeBlockData.trigger_type}`;
  if (executionTimesCache.has(cacheKey)) {
    return executionTimesCache.get(cacheKey)!;
  }

  const times: string[] = [];
  const now = new Date();

  switch (timeBlockData.trigger_type) {
    case 'delay':
      times.push(...getAllExecutionTimes(timeBlockData, flow));
      break;

    case 'specific_dates':
      if (timeBlockData.specific_dates) {
        const matchingDate = timeBlockData.specific_dates.find(dateStr => dateStr === getDateString(date));
        if (matchingDate) {
          times.push(formatExecutionTime(new Date(matchingDate)));
        }
      }
      break;

    case 'times_of_day':
      if (timeBlockData.times_of_day) {
        timeBlockData.times_of_day.forEach(time => {
          const [hours, minutes] = time.split(':').map(Number);
          const executionDate = new Date(date);
          executionDate.setHours(hours, minutes, 0, 0);
          times.push(formatExecutionTime(executionDate));
        });
      }
      break;
  }

  executionTimesCache.set(cacheKey, times);
  return times;
};

export const formatExecutionTime = (date: Date): string => {
  return date.toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit',
    hour12: true 
  });
};

// Cache for all execution times
const allExecutionTimesCache = new Map<string, string[]>();

export const getAllExecutionTimes = (timeBlockData: TimeBlockData, flow: Flow): string[] => {
  const cacheKey = `${flow.id}-${timeBlockData.trigger_type}-${timeBlockData.delay}`;
  if (allExecutionTimesCache.has(cacheKey)) {
    return allExecutionTimesCache.get(cacheKey)!;
  }

  if (timeBlockData.trigger_type === 'delay' && timeBlockData.delay) {
    const times: string[] = [];
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);

    const creationDate = flow.created_at ? new Date(flow.created_at) : startOfDay;
    const startTime = creationDate > startOfDay ? creationDate : startOfDay;

    let currentTime = new Date(startTime);
    const delay = typeof timeBlockData.delay === 'string' ? 
      parseInt(timeBlockData.delay, 10) : 
      timeBlockData.delay;

    while (currentTime <= endOfDay) {
      times.push(formatExecutionTime(currentTime));
      currentTime = new Date(currentTime.getTime() + (delay * 60000));
    }

    allExecutionTimesCache.set(cacheKey, times);
    return times;
  }
  
  return [];
};