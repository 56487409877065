import React from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectUsage } from '../../features/user/subscriptionSlice';

const UsageStats: React.FC = () => {
  const usage = useSelector(selectUsage);

  const UsageBar = ({ used, limit, label }: { used: number, limit: number | string, label: string }) => (
    <div className="bg-[#3a2b4d] p-6 rounded-xl">
      <h4 className="text-gray-400 mb-2">{label}</h4>
      <div className="flex items-end justify-between">
        <span className="text-2xl font-bold text-white">
          {used}
        </span>
        <span className="text-sm text-gray-400">
          of {limit === -1 ? '∞' : limit} used
        </span>
      </div>
      <div className="w-full h-2 bg-purple-900 rounded-full mt-2">
        <div 
          className="h-full bg-purple-600 rounded-full" 
          style={{ width: `${limit === -1 ? 0 : (used / Number(limit) * 100)}%` }}
        />
      </div>
    </div>
  );

  if (!usage) {
    return null;
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
    >
      {/* Action Flows */}
      <UsageBar 
        used={usage?.action_flows?.used || 0}
        limit={usage?.action_flows?.limit || 0}
        label="Action Flows"
      />

      {/* Notifications */}
      <div className="bg-[#3a2b4d] p-6 rounded-xl">
        <h4 className="text-gray-400 mb-2">Monthly Notifications</h4>
        <div className="space-y-4">
          <div>
            <div className="flex items-end justify-between">
              <span className="text-2xl font-bold text-white">
                {usage?.notifications?.used || 0}
              </span>
              <span className="text-sm text-gray-400">
                of {usage?.notifications?.limit === -1 ? '∞' : usage?.notifications?.limit || 0}
              </span>
            </div>
            <div className="w-full h-2 bg-purple-900 rounded-full mt-2">
              <div 
                className="h-full bg-purple-600 rounded-full" 
                style={{ width: `${usage?.notifications?.percentage || 0}%` }}
              />
            </div>
          </div>
          
          {/* Notification Channels */}
          <div className="text-sm space-y-2">
            <div>
              <span className="text-gray-400">Standard:</span>
              <span className="float-right text-white">
                {Object.values(usage?.notifications?.by_channel?.standard || {}).reduce((a, b) => a + b, 0)}
              </span>
            </div>
            <div>
              <span className="text-gray-400">Premium:</span>
              <span className="float-right text-white">{usage?.notifications?.by_channel?.premium || 0}</span>
            </div>
            <div>
              <span className="text-gray-400">Social:</span>
              <span className="float-right text-white">{usage?.notifications?.by_channel?.social || 0}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Active Alerts */}
      <div className="bg-[#3a2b4d] p-6 rounded-xl">
        <h4 className="text-gray-400 mb-2">Active Alerts</h4>
        <div className="space-y-4">
          <div>
            <div className="flex items-end justify-between">
              <span className="text-2xl font-bold text-white">
                {usage?.alerts?.active || 0}
              </span>
              <span className="text-sm text-gray-400">
                of {usage?.alerts?.limit === -1 ? '∞' : usage?.alerts?.limit || 0}
              </span>
            </div>
            <div className="w-full h-2 bg-purple-900 rounded-full mt-2">
              <div 
                className="h-full bg-purple-600 rounded-full" 
                style={{ width: `${usage?.alerts?.percentage || 0}%` }}
              />
            </div>
          </div>
          
          {/* Alert Types */}
          <div className="text-sm space-y-2">
            {Object.entries(usage?.alerts?.by_type || {}).map(([type, count]) => (
              <div key={type}>
                <span className="text-gray-400">{type.charAt(0).toUpperCase() + type.slice(1)}:</span>
                <span className="float-right text-white">
                  {count} / {usage?.alerts?.per_type_limit === -1 ? '∞' : usage?.alerts?.per_type_limit}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Other Limits */}
      <div className="bg-[#3a2b4d] p-6 rounded-xl">
        <h4 className="text-gray-400 mb-2">Other Limits</h4>
        <div className="space-y-2 text-sm">
          <div>
            <span className="text-gray-400">Trigger Delay:</span>
            <span className="float-right text-white">
              {usage?.limits?.trigger_delay_minutes} min
            </span>
          </div>
          <div>
            <span className="text-gray-400">Data Retention:</span>
            <span className="float-right text-white">
              {usage?.limits?.data_retention_days} days
            </span>
          </div>
          <div>
            <span className="text-gray-400">Check Frequency:</span>
            <span className="float-right text-white">
              {usage?.limits?.alert_check_frequency} min
            </span>
          </div>
          <div>
            <span className="text-gray-400">Email Credentials:</span>
            <span className="float-right text-white">
              {usage?.limits?.email_credentials === -1 ? '∞' : usage?.limits?.email_credentials}
            </span>
          </div>
          <div>
            <span className="text-gray-400">YouTube Credentials:</span>
            <span className="float-right text-white">
              {usage?.limits?.youtube_credentials === -1 ? '∞' : usage?.limits?.youtube_credentials}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UsageStats; 