import React, { useState, useEffect, useMemo } from "react";
import { RiAddLine } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  fetchAllAlerts,
  selectAllAlerts,
  selectLoading,
} from "../features/alerts/alertsSlice";
import CategoryModal from "../components/CategoryModal";
import NewsAlertModal from "../components/forms/NewsAlertModal";
import ListingsModal from "../components/forms/ListingsModal";
import PriceAlertsModal from "../components/forms/PriceAlertsModal";
import WeatherModal from "../components/forms/WeatherModal";
import SportModal from "../components/forms/SportModal";
import XModal from "../components/forms/XModal";
import EventModal from "../components/forms/EventModal";
import {
  CryptoPriceChangeAlert,
  EventAlert,
  GeneralAlert,
  NewListingAlert,
  Source,
  SportsNewsAlert,
  WeatherAlert,
  NewsAlert,
  XNewsAlert,
} from "../types/alertTypes";
import AlertItem from "../components/AlertItem";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { selectUsage } from "../features/user/subscriptionSlice";
import { fetchUsageStats } from "../features/user/subscriptionSlice";

const MonitorPage = () => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(selectAllAlerts);
  const loading = useAppSelector(selectLoading);
  const [alertToEdit, setAlertToEdit] = useState<GeneralAlert | null>(null);
  const [modalState, setModalState] = useState({
    isMainModalOpen: false,
    isKeywordFormOpen: false,
    isSourceFormOpen: false,
    isPriceAlertFormOpen: false,
    isListingFormOpen: false,
    isWeatherFormOpen: false,
    isSportFormOpen: false,
    isXFormOpen: false,
    isEventFormOpen: false,
  });

  const [sortKey, setSortKey] = useState<"created_at" | "last_check">(
    "created_at"
  );
  const [sortAscending, setSortAscending] = useState(true);
  const [filters, setFilters] = useState({
    active: "",
    keyword: "",
    live_feed: "",
    sources: "",
  });

  const navigate = useNavigate();
  const usage = useAppSelector(selectUsage);
  const limitReachedMessage = "Alert limit reached. Please delete existing alerts or upgrade your subscription plan to create more.";

  useEffect(() => {
    dispatch(fetchAllAlerts());
  }, [dispatch]);

  const handleCloseAllModals = () => {
    setModalState({
      isMainModalOpen: false,
      isKeywordFormOpen: false,
      isSourceFormOpen: false,
      isPriceAlertFormOpen: false,
      isListingFormOpen: false,
      isWeatherFormOpen: false,
      isSportFormOpen: false,
      isXFormOpen: false,
      isEventFormOpen: false,
    });
  };

  const handleOpenModal = async (
    option: string,
    alertToEdit: GeneralAlert | null = null
  ) => {
    setAlertToEdit(alertToEdit);
    handleCloseAllModals();
    switch (option) {
      case "News by Keyword":
        setModalState((prev) => ({ ...prev, isKeywordFormOpen: true }));
        break;
      case "News by Source":
        setModalState((prev) => ({ ...prev, isSourceFormOpen: true }));
        break;
      case "Price Alerts":
        setModalState((prev) => ({ ...prev, isPriceAlertFormOpen: true }));
        break;
      case "New Listings":
        setModalState((prev) => ({ ...prev, isListingFormOpen: true }));
        break;
      case "Weather":
        setModalState((prev) => ({ ...prev, isWeatherFormOpen: true }));
        break;
      case "Sport":
        setModalState((prev) => ({ ...prev, isSportFormOpen: true }));
        break;
      case "X News":
        setModalState((prev) => ({ ...prev, isXFormOpen: true }));
        break;
      case "Event":
        setModalState((prev) => ({ ...prev, isEventFormOpen: true }));
        break;
      default:
        console.error("Unknown category selected");
    }
    await dispatch(fetchUsageStats());
  };

  const sortedAndFilteredAlerts = useMemo(() => {
    let filteredAlerts = alerts.filter((alert) => {
      if (filters.active && filters.active === "active" && !alert.active)
        return false;
      if (filters.active && filters.active === "inactive" && alert.active)
        return false;
      if (
        filters.keyword &&
        "keyword" in alert &&
        filters.keyword &&
        !alert.keyword?.toLowerCase().includes(filters.keyword.toLowerCase())
      )
        return false;
      if (
        filters.live_feed !== "" &&
        "live_feed" in alert &&
        `${alert.live_feed}` !== filters.live_feed
      )
        return false;
      if (
        filters.sources &&
        "sources" in alert &&
        !alert.sources.some((source: Source) =>
          source.name.toLowerCase().includes(filters.sources.toLowerCase())
        )
      )
        return false;
      return true;
    });

    return filteredAlerts.sort((a, b) => {
      let aValue: any = a[sortKey];
      let bValue: any = b[sortKey];
      if (sortKey === "created_at" || sortKey === "last_check") {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      }
      if (sortAscending) {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [alerts, sortKey, sortAscending, filters]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const renderSortingAndFiltering = () => (
    <div className="w-full flex justify-center">
      <div className="relative flex flex-col items-center">
        <motion.button
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          type="button"
          className="px-4 py-2.5 rounded-xl bg-white/10 backdrop-blur-sm border border-white/20 text-sm font-medium text-white transition-all duration-300 hover:bg-white/20 hover:scale-105 flex items-center"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <FaFilter className="mr-2 text-sm" />
          Customize View
        </motion.button>

        {dropdownOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute top-12 w-[280px] rounded-xl shadow-2xl bg-white/10 backdrop-blur-xl border border-white/20"
          >
            <div className="p-4 space-y-3">
              {/* Sorting buttons */}
              <div className="flex gap-2 mb-3">
                <button
                  onClick={() => {
                    setSortKey("created_at");
                    setSortAscending(!sortAscending);
                  }}
                  className="flex-1 text-xs bg-white/5 hover:bg-white/10 px-3 py-2 rounded-lg transition-all duration-300"
                >
                  Created {sortAscending && sortKey === "created_at" ? "↓" : "↑"}
                </button>
                <button
                  onClick={() => {
                    setSortKey("last_check");
                    setSortAscending(!sortAscending);
                  }}
                  className="flex-1 text-xs bg-white/5 hover:bg-white/10 px-3 py-2 rounded-lg transition-all duration-300"
                >
                  Last Check {sortAscending && sortKey === "last_check" ? "↓" : "↑"}
                </button>
              </div>

              {/* Filter controls */}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <label className="text-xs text-white/80 w-20">Status:</label>
                  <select
                    className="flex-1 text-xs bg-white/5 text-white px-2 py-1.5 rounded-lg border border-white/10 focus:outline-none focus:border-white/30"
                    value={filters.active}
                    onChange={(e) => setFilters({ ...filters, active: e.target.value })}
                  >
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>

                <div className="flex items-center gap-2">
                  <label className="text-xs text-white/80 w-20">Live Feed:</label>
                  <select
                    className="flex-1 text-xs bg-white/5 text-white px-2 py-1.5 rounded-lg border border-white/10 focus:outline-none focus:border-white/30"
                    value={filters.live_feed}
                    onChange={(e) => setFilters({ ...filters, live_feed: e.target.value })}
                  >
                    <option value="">All</option>
                    <option value="true">Enabled</option>
                    <option value="false">Disabled</option>
                  </select>
                </div>

                <div className="flex items-center gap-2">
                  <label className="text-xs text-white/80 w-20">Keyword:</label>
                  <input
                    type="text"
                    className="flex-1 text-xs bg-white/5 text-white px-2 py-1.5 rounded-lg border border-white/10 focus:outline-none focus:border-white/30"
                    placeholder="Filter..."
                    value={filters.keyword}
                    onChange={(e) => setFilters({ ...filters, keyword: e.target.value })}
                  />
                </div>

                <div className="flex items-center gap-2">
                  <label className="text-xs text-white/80 w-20">Sources:</label>
                  <input
                    type="text"
                    className="flex-1 text-xs bg-white/5 text-white px-2 py-1.5 rounded-lg border border-white/10 focus:outline-none focus:border-white/30"
                    placeholder="Filter..."
                    value={filters.sources}
                    onChange={(e) => setFilters({ ...filters, sources: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900">
      {/* Background elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/50" />
      
      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 py-20">
        <motion.header
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient text-center">
            Alert Center
          </h2>
          <p className="text-lg text-gray-300 max-w-2xl mx-auto">
            Monitor and manage your customized alerts in real-time
          </p>
        </motion.header>

        {/* New Alert Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex justify-center mb-12"
        >
          <div className="relative group">
            <button
              onClick={() => usage && usage.alerts.active >= usage.alerts.limit 
                ? navigate('/subscription') 
                : setModalState({ ...modalState, isMainModalOpen: true })}
              className={`group relative px-8 py-4 rounded-full ${
                usage && usage.alerts.active >= usage.alerts.limit
                  ? 'bg-gray-500 hover:bg-gray-600'
                  : 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
              } text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/30 hover:scale-105`}
            >
              <span className="flex items-center">
                <RiAddLine className="mr-2 text-xl" />
                Create New Alert
              </span>
              <div className="absolute inset-0 rounded-full bg-white/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </button>
            {usage && usage.alerts.active >= usage.alerts.limit && (
              <div 
                className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap cursor-pointer z-50"
                onClick={() => navigate('/subscription')}
              >
                {limitReachedMessage} Click to upgrade.
              </div>
            )}
          </div>
        </motion.div>

        {/* Filters Section */}
        {renderSortingAndFiltering()}

        {/* Alerts Grid */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-12 grid gap-6 md:grid-cols-2 lg:grid-cols-3"
        >
          <AnimatePresence>
            {sortedAndFilteredAlerts.map((alert) => (
              <motion.div
                key={alert.type + alert.id}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 shadow-xl hover:shadow-2xl transition-all duration-300 hover:scale-[1.02]"
              >
                <AlertItem alert={alert} onEdit={handleOpenModal} />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>

        {/* Loading State */}
        {loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex justify-center items-center py-20"
          >
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500" />
          </motion.div>
        )}

        {/* Empty State */}
        {!loading && sortedAndFilteredAlerts.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-20 text-gray-400"
          >
            <p className="text-xl">No alerts found</p>
            <p className="mt-2">Create your first alert to get started</p>
          </motion.div>
        )}
      </div>

      {/* Existing Modals */}
      <CategoryModal
        isOpen={modalState.isMainModalOpen}
        onClose={() => setModalState({ ...modalState, isMainModalOpen: false })}
        onSelect={handleOpenModal}
      />
      <NewsAlertModal
        isOpen={modalState.isKeywordFormOpen}
        onClose={handleCloseAllModals}
        mode="keyword"
        alert={alertToEdit as NewsAlert}
      />
      <NewsAlertModal
        isOpen={modalState.isSourceFormOpen}
        onClose={handleCloseAllModals}
        mode="source"
        alert={alertToEdit as NewsAlert}
      />
      <PriceAlertsModal
        isOpen={modalState.isPriceAlertFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as CryptoPriceChangeAlert}
      />
      <ListingsModal
        isOpen={modalState.isListingFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as NewListingAlert}
      />
      <WeatherModal
        isOpen={modalState.isWeatherFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as WeatherAlert}
      />
      <SportModal
        isOpen={modalState.isSportFormOpen}
        onClose={handleCloseAllModals}
        mode="keyword"
        alert={alertToEdit as SportsNewsAlert}
      />
      <XModal
        isOpen={modalState.isXFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as XNewsAlert}
      />
      <EventModal
        isOpen={modalState.isEventFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as EventAlert}
      />
    </div>
  );
};

export default MonitorPage;
