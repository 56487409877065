// src/components/GenerateVideoNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import NewEmailModal from "../modals/NewEmailModal";
import { getEmailCredentials } from "../../../api/flowsService";
import { EmailCredential } from "../../../types/flowTypes";

const GenerateVideoNode = ({
  id,
  data,
  onDelete,
  onUpdate,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
  onUpdate: (id: string, newData: any) => void;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [prompt, setPrompt] = useState(data.prompt || "");
  const [template, setTemplate] = useState(data.template || "YouTube Shorts");
  const [ratio, setRatio] = useState(data.ratio || "Landscape");
  const [email, setEmail] = useState(data.email || "");
  const [password, setPassword] = useState(data.password || "password");

  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();

  const [emailCredentials, setEmailCredentials] = useState<EmailCredential[]>([]);
  const [selectedCredential, setSelectedCredential] = useState<EmailCredential | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  useEffect(() => {
    loadEmailCredentials();
  }, []);

  const loadEmailCredentials = async () => {
    try {
      const credentials = await getEmailCredentials();
      setEmailCredentials(credentials);
      
      if (data.credentialId && credentials.length > 0) {
        const savedCredential = credentials.find(c => c.id === data.credentialId);
        if (savedCredential) {
          setSelectedCredential(savedCredential);
        }
      }
    } catch (error) {
      console.error("Failed to load email credentials:", error);
    }
  };

  const updateNodeData = (newData: any) => {
    onUpdate(id, { ...data, ...newData });
  };

  useEffect(() => {
    updateNodeData({
      prompt,
      template,
      ratio,
      email,
      password,
    });
  }, [prompt, template, ratio, email, password]);

  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const renderEmailDropdown = () => (
    <div className="relative">
      <div
        className={`${darkBlueInputStyle} w-full cursor-pointer flex justify-between items-center`}
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <span>{selectedCredential?.email_address || "Select an Email"}</span>
        <span className="ml-2">&#9660;</span>
      </div>
      {dropdownVisible && (
        <ul className="absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10">
          {emailCredentials.map((credential) => (
            <li key={credential.id} className="p-2 hover:bg-gray-700">
              <div className="flex justify-between items-center">
                <span className="cursor-pointer"
                  onClick={() => {
                    setSelectedCredential(credential);
                    setDropdownVisible(false);
                    updateNodeData({
                      credentialId: credential.id,
                      email: credential.email_address
                    });
                  }}>
                  {credential.email_address}
                </span>
                <button
                  className="ml-2 px-2 py-1 bg-blue-500 rounded hover:bg-blue-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedCredential(credential);
                    setModalVisible(true);
                    setDropdownVisible(false);
                  }}
                >
                  Edit
                </button>
              </div>
            </li>
          ))}
          <li
            className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
            onClick={() => {
              setSelectedCredential(null);
              setModalVisible(true);
              setDropdownVisible(false);
            }}
          >
            Add Email
          </li>
        </ul>
      )}
    </div>
  );

  const handleAddEmailAccount = async (credential: EmailCredential) => {
    try {
      await loadEmailCredentials();
      
      setSelectedCredential(credential);
      setModalVisible(false);
      
      updateNodeData({
        credentialId: credential.id,
        email: credential.email_address
      });
    } catch (error) {
      console.error("Error adding email account:", error);
    }
  };

  const handleEditCredential = (credential: EmailCredential) => {
    setSelectedCredential(credential);
    setModalVisible(true);
    setDropdownVisible(false);
  };

  return (
    <NodeContainer
      id={id}
      className="bg-[#1A9B97]"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold">Generate Video</h3>

      <div className="mb-2 bg-[#15807C] p-2 rounded">
        <div className="flex justify-between items-center">
          <h4 className="text-sm font-medium">InVideo Account</h4>
        </div>
        
        <div className="text-xs text-gray-300 mb-2 italic">
          Don't have an account? <a 
            href="https://invideo.io/signup" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-200 hover:text-blue-100 ml-1 underline"
          >
            Sign up for free
          </a>
        </div>

        {renderEmailDropdown()}
      </div>

      <div className="mb-4">
        <label className="block text-sm">Template:</label>
        <select
          value={template}
          onChange={(e) => setTemplate(e.target.value)}
          className={`${darkBlueInputStyle} w-full`}
        >
          <option value="YouTube Shorts">YouTube Shorts</option>
          {/* <option value="News">News</option>
          <option value="Tutorial">Tutorial</option>
          <option value="Review">Review</option> */}
        </select>

        {/* <label className="block mt-2 text-sm">Ratio:</label>
        <select
          value={ratio}
          onChange={(e) => setRatio(e.target.value)}
          className={`${darkBlueInputStyle} w-full`}
        >
          <option value="Landscape">Landscape</option>
          <option value="Square">Square</option>
          <option value="Portrait">Portrait</option>
        </select> */}

        <label className="block mt-2 text-sm">Prompt:</label>
        <textarea
          placeholder="Enter prompt for video generation"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className={`${darkBlueInputStyle} w-full min-h-[80px]`}
        />
      </div>

      <NodeHandle
        id="signal-in"
        type="target"
        position={Position.Left}
        label="Send Signal"
        style={{ top: "20%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Start video generation signal from the previous block.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="text-in"
        type="target"
        position={Position.Left}
        label="Prompt"
        style={{ top: "40%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: prompt for video generation.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="signal-out"
        type="source"
        position={Position.Right}
        label="Completed"
        style={{ top: "50%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Video generation completed successfully.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="file-out"
        type="source"
        position={Position.Right}
        label="File"
        style={{ top: "70%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Generated video file.")}
        onLeave={handleMouseLeave}
      />

      <NodeHandle
        id="error-out"
        type="source"
        position={Position.Right}
        label="Failed"
        style={{ top: "90%", transform: "translateY(-50%)" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Video generation failed.")}
        onLeave={handleMouseLeave}
      />

      {modalVisible && (
        <NewEmailModal
          onClose={() => setModalVisible(false)}
          onAddEmail={handleAddEmailAccount}
          editCredential={selectedCredential || undefined}
        />
      )}
    </NodeContainer>
  );
};

export default GenerateVideoNode;