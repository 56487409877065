import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { RiArrowRightSLine, RiLoader4Line, RiRefreshLine } from 'react-icons/ri';
import { FiTrendingUp, FiClock, FiGlobe } from 'react-icons/fi';

// Definicje interfejsów
interface NewsItem {
  title: string;
  description: string;
  url: string;
  urlToImage: string;
  publishedAt: string;
  source: {
    name: string;
  };
}

interface StockData {
  symbol: string;
  data: {
    time: string;
    value: number;
  }[];
}

interface Stock {
  symbol: string;
  name: string;
}

const popularStocks: Stock[] = [
  { symbol: 'AAPL', name: 'Apple' },
  { symbol: 'MSFT', name: 'Microsoft' },
  { symbol: 'GOOGL', name: 'Alphabet (Google)' },
  { symbol: 'AMZN', name: 'Amazon' },
  { symbol: 'NVDA', name: 'NVIDIA' },
  { symbol: 'META', name: 'Meta Platforms' },
  { symbol: 'BRK-B', name: 'Berkshire Hathaway' },
  { symbol: 'TSLA', name: 'Tesla' },
  { symbol: 'V', name: 'Visa' },
  { symbol: 'UNH', name: 'UnitedHealth' },
  { symbol: 'JNJ', name: 'Johnson & Johnson' },
  { symbol: 'JPM', name: 'JPMorgan Chase' },
  { symbol: 'XOM', name: 'ExxonMobil' },
  { symbol: 'WMT', name: 'Walmart' },
  { symbol: 'MA', name: 'Mastercard' },
  { symbol: 'PG', name: 'Procter & Gamble' },
  { symbol: 'HD', name: 'Home Depot' },
  { symbol: 'CVX', name: 'Chevron' },
  { symbol: 'BAC', name: 'Bank of America' },
  { symbol: 'ABBV', name: 'AbbVie' },
  { symbol: 'PFE', name: 'Pfizer' },
  { symbol: 'AVGO', name: 'Broadcom' },
  { symbol: 'COST', name: 'Costco' },
  { symbol: 'DIS', name: 'Disney' },
  { symbol: 'KO', name: 'Coca-Cola' },
  { symbol: 'PEP', name: 'PepsiCo' },
  { symbol: 'TMO', name: 'Thermo Fisher' },
  { symbol: 'CSCO', name: 'Cisco' },
  { symbol: 'MRK', name: 'Merck' },
  { symbol: 'ABT', name: 'Abbott' },
  { symbol: 'ADBE', name: 'Adobe' },
  { symbol: 'CMCSA', name: 'Comcast' },
  { symbol: 'NFLX', name: 'Netflix' },
  { symbol: 'NKE', name: 'Nike' },
  { symbol: 'INTC', name: 'Intel' },
  { symbol: 'VZ', name: 'Verizon' },
  { symbol: 'QCOM', name: 'Qualcomm' },
  { symbol: 'CRM', name: 'Salesforce' },
  { symbol: 'T', name: 'AT&T' },
  { symbol: 'UPS', name: 'UPS' },
  { symbol: 'AMD', name: 'Advanced Micro Devices' },
  { symbol: 'IBM', name: 'IBM' },
  { symbol: 'GS', name: 'Goldman Sachs' },
  { symbol: 'MS', name: 'Morgan Stanley' },
  { symbol: 'C', name: 'Citigroup' },
  { symbol: 'PYPL', name: 'PayPal' },
  { symbol: 'ORCL', name: 'Oracle' },
  { symbol: 'HON', name: 'Honeywell' },
  { symbol: 'RTX', name: 'Raytheon Technologies' },
  { symbol: 'SBUX', name: 'Starbucks' },
  { symbol: 'AMT', name: 'American Tower' },
  { symbol: 'BA', name: 'Boeing' },
  { symbol: 'CAT', name: 'Caterpillar' },
  { symbol: 'TXN', name: 'Texas Instruments' },
  { symbol: 'AMAT', name: 'Applied Materials' },
  { symbol: 'GE', name: 'General Electric' },
  { symbol: 'NEE', name: 'NextEra Energy' },
  { symbol: 'LLY', name: 'Eli Lilly' },
  { symbol: 'INTU', name: 'Intuit' },
  { symbol: 'LIN', name: 'Linde' },
  { symbol: 'SCHW', name: 'Charles Schwab' },
  { symbol: 'AXP', name: 'American Express' },
  { symbol: 'BKNG', name: 'Booking Holdings' },
  { symbol: 'DE', name: 'John Deere' },
  { symbol: 'ISRG', name: 'Intuitive Surgical' },
  { symbol: 'MMM', name: '3M' },
  { symbol: 'MO', name: 'Altria Group' },
  { symbol: 'PM', name: 'Philip Morris' },
  { symbol: 'BLK', name: 'BlackRock' },
  { symbol: 'MDLZ', name: 'Mondelez' },
  { symbol: 'SPGI', name: 'S&P Global' },
  { symbol: 'UNP', name: 'Union Pacific' },
  { symbol: 'AMGN', name: 'Amgen' },
  { symbol: 'CVS', name: 'CVS Health' },
  { symbol: 'TGT', name: 'Target' },
  { symbol: 'GILD', name: 'Gilead Sciences' },
  { symbol: 'MMC', name: 'Marsh & McLennan' },
  { symbol: 'SYK', name: 'Stryker' },
  { symbol: 'ZTS', name: 'Zoetis' },
  { symbol: 'PLD', name: 'Prologis' },
  { symbol: 'DUK', name: 'Duke Energy' },
  { symbol: 'LRCX', name: 'Lam Research' },
  { symbol: 'SO', name: 'Southern Company' },
  { symbol: 'BDX', name: 'Becton Dickinson' },
  { symbol: 'ADI', name: 'Analog Devices' },
  { symbol: 'GM', name: 'General Motors' },
  { symbol: 'F', name: 'Ford' },
  { symbol: 'CCI', name: 'Crown Castle' },
  { symbol: 'ETN', name: 'Eaton' },
  { symbol: 'EMR', name: 'Emerson Electric' },
  { symbol: 'EW', name: 'Edwards Lifesciences' },
  { symbol: 'FIS', name: 'Fidelity National Information' },
  { symbol: 'HCA', name: 'HCA Healthcare' },
  { symbol: 'ICE', name: 'Intercontinental Exchange' },
  { symbol: 'KLAC', name: 'KLA Corporation' },
  { symbol: 'MET', name: 'MetLife' },
  { symbol: 'NOC', name: 'Northrop Grumman' },
  { symbol: 'PNC', name: 'PNC Financial Services' },
  { symbol: 'REGN', name: 'Regeneron Pharmaceuticals' },
  { symbol: 'SHW', name: 'Sherwin-Williams' },
  { symbol: 'TJX', name: 'TJX Companies' },
  { symbol: 'USB', name: 'U.S. Bancorp' },
  { symbol: 'WFC', name: 'Wells Fargo' },
  { symbol: 'YUM', name: 'Yum! Brands' },
  { symbol: 'ZBH', name: 'Zimmer Biomet' },
  { symbol: 'SNAP', name: 'Snap' },
  { symbol: 'UBER', name: 'Uber' },
  { symbol: 'LYFT', name: 'Lyft' },
  { symbol: 'PINS', name: 'Pinterest' },
  { symbol: 'TWTR', name: 'Twitter' }
];

const NEWS_API_KEY = '8c55167dec414664aded70225a104c6a';

const NewsPage: React.FC = () => {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedStock, setSelectedStock] = useState<Stock>(popularStocks[0]);
  const [error, setError] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrowanie firm na podstawie wyszukiwania
  const filteredStocks = popularStocks.filter(stock =>
    stock.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    stock.symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://newsapi.org/v2/everything', {
        params: {
          q: `${selectedStock.name} stock`,
          apiKey: NEWS_API_KEY,
          language: 'en',
          sortBy: 'publishedAt',
        },
      });
      setNews(response.data.articles.slice(0, 6));
      setError('');
    } catch (error) {
      console.error('Error fetching news:', error);
      setError('Failed to fetch news');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [selectedStock]);

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900 pb-20">
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/20" />

      <div className="relative z-10 pt-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-5xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient text-center">
              Market Insights
            </h1>
            <p className="mt-4 text-gray-300 text-lg">
              Latest news from the world's top companies
            </p>
          </motion.div>

          {/* Wyszukiwarka firm */}
          <div className="sticky top-0 z-50 backdrop-blur-xl py-4 mb-8 border-b border-white/20">
            <div className="flex flex-col items-center gap-4">
              <input
                type="text"
                placeholder="Search companies..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full max-w-md px-4 py-2 rounded-xl border border-white/20 bg-white/10 backdrop-blur-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <select
                value={selectedStock.symbol}
                onChange={(e) => {
                  const stock = popularStocks.find(s => s.symbol === e.target.value);
                  if (stock) setSelectedStock(stock);
                }}
                className="w-full max-w-md px-4 py-2 rounded-xl border border-white/20 bg-white/10 backdrop-blur-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                {filteredStocks.map((stock) => (
                  <option key={stock.symbol} value={stock.symbol}>
                    {stock.name} ({stock.symbol})
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* News Section */}
          <div className="mb-12">
            <h2 className="text-3xl font-bold mb-8 text-center text-white">
              Latest {selectedStock.name} News
            </h2>

            {/* News Content */}
            {error && (
              <div className="text-red-400 text-center mb-4">
                {error}
              </div>
            )}
            
            {loading ? (
              <div className="flex justify-center">
                <RiLoader4Line className="animate-spin text-4xl text-purple-500" />
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <AnimatePresence>
                  {news.map((article, index) => (
                    <motion.a
                      key={article.url}
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ delay: index * 0.1 }}
                      className="backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 shadow-xl hover:shadow-2xl transition-all duration-300 hover:scale-[1.02] overflow-hidden"
                    >
                      <div className="relative h-48">
                        <img
                          src={article.urlToImage || '/placeholder-news.jpg'}
                          alt={article.title}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.src = '/placeholder-news.jpg';
                          }}
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent" />
                      </div>
                      <div className="p-6">
                        <h3 className="text-lg font-semibold mb-2 line-clamp-2 text-white">
                          {article.title}
                        </h3>
                        <p className="text-gray-300 text-sm mb-4 line-clamp-3">
                          {article.description}
                        </p>
                        <div className="flex items-center justify-between text-sm text-gray-400">
                          <div className="flex items-center">
                            <FiGlobe className="mr-1" />
                            {article.source.name}
                          </div>
                          <div className="flex items-center">
                            <FiClock className="mr-1" />
                            {new Date(article.publishedAt).toLocaleDateString()}
                          </div>
                        </div>
                      </div>
                    </motion.a>
                  ))}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;