import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { FaInfoCircle } from "react-icons/fa";

type Tab = "calendar" | "new-action" | "action-list";

interface SidebarProps {
  onTabSelect: (tab: Tab) => void;
  tab: Tab;
}

const Sidebar: React.FC<SidebarProps> = ({ onTabSelect, tab }) => {
  const [activeTab, setActiveTab] = useState<Tab>("calendar");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const tabs = [
    { id: "calendar", label: "Schedule", icon: "📅" },
    { id: "new-action", label: "New Flow", icon: "✏️" },
    { id: "action-list", label: "Manage", icon: "📋" },
  ];

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
    onTabSelect(tab);
    if (window.innerWidth <= 768) {
      setIsOpen(false); // Close sidebar on mobile once a tab is clicked
    }
  };

  useEffect(()=>{
    handleTabClick(tab);
  },[tab])

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  return (
    <>
      <div
        ref={sidebarRef}
        className={classNames(
          "fixed top-0 left-0 h-screen transform transition-transform duration-300 bg-[#15061a] text-white w-[15%] z-20",
          {
            "-translate-x-full": !isOpen,
            "translate-x-0": isOpen,
          }
        )}
      >
        <div className="flex items-center mb-1 p-4 ml-5 mt-20">
          <h2 className="text-3xl font-semibold text-transparent bg-clip-text bg-default-gradient">Action Flow</h2>
          <div className="relative group ml-2">
            <FaInfoCircle className="text-gray-400 hover:text-gray-200 cursor-help transition-colors text-xl" />
            <div className="absolute left-0 w-72 p-4 mt-2 bg-gray-800 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50 text-sm">
              <h3 className="font-semibold text-indigo-400 mb-2">Welcome to Action Flow!</h3>
              <p className="text-gray-300 mb-2">
                Action Flow is your intelligent automation companion that helps you create, schedule, and manage automated workflows with ease.
              </p>
              <ul className="text-gray-300 list-disc list-inside space-y-1">
                <li>Create custom automation flows</li>
                <li>Schedule actions with flexible timing</li>
                <li>Monitor execution status in real-time</li>
                <li>Streamline repetitive tasks effortlessly</li>
              </ul>
              <p className="text-gray-400 mt-2 italic">
                Start building your first flow and let our enterprise-grade automation system work for you!
              </p>
            </div>
          </div>
        </div>
        {/* <div className="text-lg font-bold mx-auto px-5 text-yellow-500"> WARNING: This is a system demo, actions will start being executed on 20.10.2024</div> */}
        <ul className="space-y-2 px-4">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => handleTabClick(tab.id as Tab)}
              className={`flex text-lg items-center p-2 rounded cursor-pointer ${
                activeTab === tab.id ? "bg-gray-700" : "hover:bg-gray-700"
              }`}
            >
              <span className="mr-2">{tab.icon}</span>
              <span>{tab.label}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Toggle Button */}
      {!isOpen && (
        <button
          onClick={toggleSidebar}
          className="fixed top-1/2 -left-12 transform flex items-center justify-center w-16 h-16 bg-gray-800 bg-opacity-80 rounded-full z-30"
          style={{
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-white"
          >
            <path
              d="M8 5L16 12L8 19"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default Sidebar;