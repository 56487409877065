import axios from './axios';
import { Flow, Block, Connection, EmailCredential, YouTubeCredential } from '../types/flowTypes';

// Flows API
export const getFlows = async (): Promise<Flow[]> => {
    const response = await axios.get(`actions/flows/`);
    return response.data;
};

export const createFlow = async (flow: Flow): Promise<Flow> => {
    const response = await axios.post(`actions/flows/`, flow);
    return response.data;
};

export const updateFlow = async (id: string, flow: Flow): Promise<Flow> => {
    const response = await axios.put(`actions/flows/${id}/`, flow);
    return response.data;
};

export const deleteFlow = async (id: string): Promise<void> => {
    await axios.delete(`actions/flows/${id}/`);
};

// Blocks API
export const getBlocksByFlow = async (flowId: string): Promise<Block[]> => {
    const response = await axios.get(`actions/blocks/?flow=${flowId}`);
    return response.data;
};

export const createBlock = async (block: Block): Promise<Block> => {
    const response = await axios.post(`actions/blocks/`, block);
    return response.data;
};

export const updateBlock = async (id: string, block: Block): Promise<Block> => {
    const response = await axios.put(`actions/blocks/${id}/`, block);
    return response.data;
};

export const deleteBlock = async (id: string): Promise<void> => {
    await axios.delete(`actions/blocks/${id}/`);
};

// Connections API
export const getConnectionsByFlow = async (flowId: string): Promise<Connection[]> => {
    const response = await axios.get(`actions/connections/?flow=${flowId}`);
    return response.data;
};

export const createConnection = async (connection: Connection): Promise<Connection> => {
    const response = await axios.post(`actions/connections/`, connection);
    return response.data;
};

export const updateConnection = async (id: string, connection: Connection): Promise<Connection> => {
    const response = await axios.put(`actions/connections/${id}/`, connection);
    return response.data;
};

export const deleteConnection = async (id: string): Promise<void> => {
    await axios.delete(`actions/connections/${id}/`);
};

// Email Credentials API
export const getEmailCredentials = async (): Promise<EmailCredential[]> => {
    const response = await axios.get('actions/email-credentials/');
    return response.data;
};

export const createEmailCredential = async (credential: Partial<EmailCredential>): Promise<EmailCredential> => {
    try {
        const response = await axios.post('actions/email-credentials/', credential);
        return response.data;
    } catch (error: any) {
        if (error.response?.data?.code === "duplicate_email") {
            throw new Error("This email address is already registered");
        }
        throw error;
    }
};

export const testEmailConnection = async (id: string): Promise<{ status: string; message: string }> => {
    const response = await axios.post(`actions/email-credentials/${id}/test_connection/`);
    return response.data;
};

export const getEmailFlowHistory = async (id: string): Promise<any> => {
    const response = await axios.get(`actions/email-credentials/${id}/flow_history/`);
    return response.data;
};

export const updateEmailCredential = async (id: string, credential: Partial<EmailCredential>): Promise<EmailCredential> => {
    const response = await axios.put(`actions/email-credentials/${id}/`, credential);
    return response.data;
};

// YouTube Credentials API
export const getYouTubeCredentials = async (): Promise<YouTubeCredential[]> => {
    const response = await axios.get('actions/youtube-credentials/');
    return response.data;
};

export const createYouTubeCredential = async (credential: {
    channel_name: string;
    access_token: string;
    refresh_token: string;
    token_expiry: string;
}): Promise<YouTubeCredential> => {
    try {
        const response = await axios.post('actions/youtube-credentials/', credential);
        return response.data;
    } catch (error: any) {
        if (error.response?.data?.code === "duplicate_channel") {
            throw new Error("This YouTube channel is already registered");
        }
        throw error;
    }
};

export const verifyYouTubeCredentials = async (id: string): Promise<{ status: string; message: string }> => {
    const response = await axios.post(`actions/youtube-credentials/${id}/verify_credentials/`);
    return response.data;
};

export const updateYouTubeCredential = async (id: string, credential: Partial<YouTubeCredential>): Promise<YouTubeCredential> => {
    const response = await axios.put(`actions/youtube-credentials/${id}/`, credential);
    return response.data;
};