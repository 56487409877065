import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBlocksByFlow, createBlock, updateBlock, deleteBlock } from '../../api/flowsService';
import { Block } from '../../types/flowTypes';

// Define the state type
interface BlocksState {
    items: Block[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Define the initial state with the correct type
const initialState: BlocksState = {
    items: [],
    status: 'idle',
    error: null,
};

export const fetchBlocksByFlow = createAsyncThunk('blocks/fetchBlocksByFlow', async (flowId: string) => {
    return await getBlocksByFlow(flowId);
});

export const addBlock = createAsyncThunk('blocks/addBlock', async (newBlock: Block) => {
    return await createBlock(newBlock);
});

export const modifyBlock = createAsyncThunk('blocks/modifyBlock', async ({ id, updatedBlock }: {id: string, updatedBlock: Block}) => {
    return await updateBlock(id, updatedBlock);
});

export const removeBlock = createAsyncThunk('blocks/removeBlock', async (id: string) => {
    await deleteBlock(id);
    return id;
});

const blocksSlice = createSlice({
    name: 'blocks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlocksByFlow.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBlocksByFlow.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchBlocksByFlow.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message as string;
            })
            .addCase(addBlock.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(modifyBlock.fulfilled, (state, action) => {
                const updatedBlock = action.payload;
                const index = state.items.findIndex((block: Block) => block.id === updatedBlock.id);
                if (index !== -1) {
                    state.items[index] = updatedBlock;
                }
            })
            .addCase(removeBlock.fulfilled, (state, action) => {
                state.items = state.items.filter((block: Block) => block.id !== action.payload);
            });
    }
});

export default blocksSlice.reducer;