import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ToastMessage } from './types';

interface ToastContainerProps {
  messages: ToastMessage[];
  removeToast: (id: string) => void;
}

const toastAnimations = {
  success: {
    initial: { opacity: 0, y: 50, scale: 0.3 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0 },
    transition: { type: "spring", stiffness: 500, damping: 30 }
  },
  error: {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 30,
      animate: {
        x: [0, -10, 10, -10, 10, 0],
        transition: { duration: 0.5 }
      }
    }
  },
  warning: {
    initial: { opacity: 0, y: 50, rotate: -10 },
    animate: { opacity: 1, y: 0, rotate: 0 },
    exit: { opacity: 0 },
    transition: { type: "spring", stiffness: 400, damping: 25 }
  },
  info: {
    initial: { opacity: 0, y: 50, scale: 0.8 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0 },
    transition: { type: "tween", duration: 0.4 }
  }
};

const ToastContainer: React.FC<ToastContainerProps> = ({ messages, removeToast }) => {
  return (
    <div className="fixed bottom-[10%] left-1/2 transform -translate-x-1/2 z-50 flex flex-col-reverse gap-2">
      <AnimatePresence>
        {messages.map((message) => (
          <motion.div
            key={message.id}
            {...toastAnimations[message.type]}
            className={`rounded-lg shadow-lg px-6 py-4 min-w-[300px] max-w-[500px] ${message.style}`}
          >
            <div className="flex items-start gap-3">
              {message.icon && (
                <motion.span 
                  className="text-xl mt-0.5"
                  animate={message.type === 'error' ? { 
                    rotate: [0, -10, 10, -10, 10, 0],
                  } : {}}
                  transition={{ duration: 0.5 }}
                >
                  {message.icon}
                </motion.span>
              )}
              <div className="flex-1">
                {message.title && (
                  <h4 className="font-semibold mb-1">{message.title}</h4>
                )}
                <p className="text-sm">{message.content}</p>
                {message.onClick && (
                  <button 
                    onClick={message.onClick}
                    className="mt-2 px-4 py-1.5 bg-white/10 hover:bg-white/20 rounded-md text-sm font-medium transition-colors"
                  >
                    Upgrade Subscription
                  </button>
                )}
              </div>
              <button 
                onClick={() => removeToast(message.id)}
                className="text-white/60 hover:text-white transition-colors"
              >
                ×
              </button>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastContainer; 